export default {
    icon: ({color = "purple", iconClass = "fas fa-circle", size = 24} = {}) => {
        const markerHtmlStyles = "background-color: " + color + ";\n" +
            "  width: " + size + "px;\n" +
            "  height: " + size + "px;\n" +
            "  display: block;\n" +
            "  left: -" + size / 2 + "px;\n" +
            "  top: -" + size / 2 + "px;\n" +
            "  position: relative;\n" +
            "  border-radius: " + size + "px " + size + "px 0;\n" +
            "  transform: rotate(45deg);\n" +
            "  border: 1px solid #FFFFFF"
        return window.L.divIcon({
            className: "my-custom-pin",
            iconAnchor: [0, size / 3 * 2],
            labelAnchor: [-size / 2, 0],
            popupAnchor: [0, -size],
            html: `<span style="${markerHtmlStyles}" ><i class="${iconClass} text-white d-flex" style="margin-top: ${size / 10}px; margin-left: ${size / 6}px; font-size: ${size / 4 * 2}px; transform: rotate(-45deg);"></i></span>`
        })
    }
}