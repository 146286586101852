<template>
  <div class="body">
    <map-container class="position-unset" ref="mapRef" style="width: 100%; height: 100%">
      <template #inner>
        <slot name="inner"/>
        <div class="search-field">
          <v-form @submit.prevent="">
            <v-text-field
                density="compact"
                variant="solo"
                :label="$t('selector_map.search')"
                v-model="search"
                append-inner-icon="mdi-card-search"
                single-line
                hide-details
                @change="makeSearch($event.target.value)"
            ></v-text-field>
          </v-form>
          <div v-if="searchResults">
            <v-row class="m-0 p-1 mt-1 justify-space-between align-items-center bg-grey-darken-1">
              <div>{{ $t('selector_map.search_results') }}</div>
              <i class="fas fa-window-close" style="cursor: pointer" @click="searchResults=null; search=null;"/>
            </v-row>
            <v-card class="search-result">
              <v-divider class="m-0"/>
              <v-card-subtitle class="text-left">
                <v-list-item v-for="result in searchResults" :key="result.osm_id" class="p-0"
                             @click="searchedSelected(result)">
                  <p class="moving-text">{{ result.display_name }}</p>
                </v-list-item>
                <v-list-item v-if="searchResults.length === 0">{{ $t('selector_map.not_found') }}</v-list-item>
              </v-card-subtitle>
            </v-card>
          </div>
        </div>
      </template>
    </map-container>
  </div>
</template>

<script>
import nominatim from "@/api/nominatim";
import MapContainer from "@/components/MapContainer";
import MapComponents from "@/components/mapComponents";

var L = window.L;
var onChangeLat = null;
var onChangeLon = null;

export default {
  name: "SelectorMap",
  components: {MapContainer},
  props: ['lat1', 'lon1'],
  data: () => ({
    search: null,
    searchResults: null,
    marker: null,
    myLat: null,
    myLon: null,
    mapId: "map-" + (new Date()).getTime()
  }),
  methods: {
    changeLat(newVal) {
      this.myLat = newVal;
      if (this.myLon) {
        if (this.marker) {
          this.$refs.mapRef.map.removeLayer(this.marker)
        }
        this.marker = L.marker([this.myLat, this.myLon]).addTo(this.$refs.mapRef.map);
        this.$refs.mapRef.map.panTo(new L.LatLng(this.myLat, this.myLon));
      }
    },
    changeLon(newVal) {
      this.myLon = newVal;
      if (this.myLat) {
        if (this.marker) {
          this.$refs.mapRef.map.removeLayer(this.marker)
        }
        this.marker = L.marker([this.myLat, this.myLon]).addTo(this.$refs.mapRef.map);
        this.$refs.mapRef.map.panTo(new L.LatLng(this.myLat, this.myLon));
      }
    },
    async makeSearch(value) {
      if (value) {
        this.searchResults = await nominatim.search(value);
      } else {
        this.searchResults = null;
      }
    },
    async searchedSelected(result) {
      this.myLat = Number(result.lat);
      this.myLon = Number(result.lon);
      if (this.marker) {
        this.$refs.mapRef.map.removeLayer(this.marker)
      }
      this.marker = L.marker([this.myLat, this.myLon], {icon: MapComponents.icon()}).addTo(this.$refs.mapRef.map);
      this.$refs.mapRef.map.panTo(new L.LatLng(this.myLat, this.myLon));
      const address = await nominatim.reverse(this.myLat, this.myLon);
      const addressTitle = address.display_name;
      this.$emit('onMarkerChanged', {coordinates: new L.LatLng(this.myLat, this.myLon), address: addressTitle})
      this.searchResults = null;
      this.search = null;
    }
  },
  mounted() {
    var that = this;
    this.$refs.mapRef.map.on('click', function (event) {
      that.searchResults = null;
      var coordinates = event.latlng;
      if (that.marker) {
        that.$refs.mapRef.map.removeLayer(that.marker)
      }
      that.marker = L.marker(coordinates, {icon: MapComponents.icon()}).bindPopup("").addTo(that.$refs.mapRef.map);
      that.$emit('onMarkerChanged', {coordinates: coordinates})
    });
    onChangeLat = function (newVal) {
      that.changeLat(newVal);
    }
    onChangeLon = function (newVal) {
      that.changeLon(newVal);
    }
  },
  watch: {
    lat1(newVal) {
      if (onChangeLat) {
        onChangeLat(newVal)
      }
    },
    lon1(newVal) {
      if (onChangeLon) {
        onChangeLon(newVal)
      }
    }
  }
}
</script>

<style scoped>
.body {
  height: 100%;
}

.search-field {
  z-index: 9999;
  right: 10px;
  margin-top: 10px;
  width: 30vw;
  max-width: 300px;
  min-width: 150px;
}

.search-result {
  overflow: auto;
  width: 100%;
  position: absolute;
  max-height: 175%;
}

@media only screen and (max-width: 767px) {
  .body {
    height: 50vh;
    margin-left: 10px;
  }

  .search-field {
    width: 50%;
  }

  .search-result {
    max-height: 225%;
  }
}

.moving-text:hover {
  animation-play-state: paused;
}

/* The animation */
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-text {
    animation: marquee 15s linear infinite;
  }
}
</style>