<template>
  <v-app id="inspire">
    <v-content>
      <div>
        <Header></Header>
        <div class="scrollbar" ref="scroller" id="style-6">
          <RouterPage/>
          <Footer></Footer>
        </div>
      </div>
      <!-- BACK TO TOP BUTTON  -->
      <a @click="scroll" role="button" v-show="visible" class="shadow btn-primary rounded-circle back-to-top">
        <i class="fas fa-chevron-up"></i>
      </a>
    </v-content>
  </v-app>
</template>

<script>
import Header from './pages/HeaderComp.vue'
import Footer from './pages/FooterComp.vue'
import RouterPage from './routes/RouterPage.vue'

export default {
  name: 'SayberDATA',
  components: {
    Header,
    RouterPage,
    Footer
  },
  data () {
    return {
      visible: false
    }
  },
  methods:{
    scroll(){
      this.intervalId = setInterval(() => {
        if (this.$refs.scroller.scrollTop === 0) {
          clearInterval(this.intervalId)
        }
        this.$refs.scroller.scrollTop = this.$refs.scroller.scrollTop - 50;
      }, 20)
    },
    scrollListener() {
      this.visible = this.$refs.scroller.scrollTop > 150
    }
  },
  mounted() {
    this.$refs.scroller.addEventListener('scroll', this.scrollListener)
  },
  beforeUnmount() {
    this.$refs.scroller.removeEventListener('scroll', this.scrollListener)
  }
}
</script>
<style>
body::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  height: calc(100vh - 106px);
  width: 100vw;
  background: #F5F5F5;
  overflow-y: scroll;
}

#style-6::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
}

#style-6::-webkit-scrollbar-thumb {
  background-color: #b070b0;
  background-image: -webkit-linear-gradient(45deg,
  rgba(255, 255, 255, .2) 25%,
  transparent 25%,
  transparent 50%,
  rgba(255, 255, 255, .2) 50%,
  rgba(255, 255, 255, .2) 75%,
  transparent 75%,
  transparent)
}
</style>