<template>
  <div class="position-relative" style="height: 100%; width: 100%">
    <div :id="mapId" class="position-unset" style="width: calc(100% - 10px); height: 100%">
    </div>
    <div style="z-index: 999;right:20px; top:0; position: absolute">
      <slot name="inner"/>
    </div>
  </div>
</template>

<script>
const L = window.L;
export default {
  name: "MapContainer",
  data: () => ({
    markers: null,
    map: null,
    mapId: "map-" + (new Date()).getTime()
  }),
  props: {
    onMapCreated: Function
  },
  mounted() {
    this.map = new L.Map(this.mapId).setView([41.00727683038745, 28.980582374035034], 13);
    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      maxZoom: 19,
    }).addTo(this.map);
    this.map.addControl(new L.Control.Fullscreen({
      title: {
        'false': 'View Fullscreen',
        'true': 'Exit Fullscreen'
      }
    }));
    if (this.onMapCreated) {
      this.onMapCreated(this.map);
    }
    this.addTargetToUrls();
  },
  methods: {
    addTargetToUrls(){
      const elWithLinks = document.querySelectorAll("a[href^='https://leafletjs.com']");
      elWithLinks.forEach( (el)=>{
        el.setAttribute('target', '_blank');
      });
    },
    addMarkers(markers) {
      if (this.markers) {
        this.markers.forEach(it => {
          this.map.removeLayer(it)
        });
      }
      this.markers = markers;
      this.markers.forEach(it => {
        it.addTo(this.map)
      });
      var group = new L.featureGroup(this.markers);
      this.map.fitBounds(group.getBounds());
    },
  }
}
</script>

<style scoped>

</style>