<template>
  <div :class="'col-sm-9' + (disabled ? ' text-grey' : ' text-purple')" :role="disabled ? 'text' : 'button'">
    <v-form v-model="valid" ref="form" @submit.prevent="">
      <div v-if="!edited" @click="!edited && !disabled ? edited=true : null"><span>{{ value }}</span> <i class="fas fa-edit"/></div>
      <v-row v-model="valid" v-else>
        <div v-if="type == 'phone'"
             :class="'teldiv ' + (!(this.telValid.valid !== false && this.telValid.formatted === this.val) ? 'tel-error' : '')"
             :data-content="$t('rules.invalid_phone_number')">
          <vue-tel-input
              mode="international"
              v-model="val"
              :label="$t('registration.phone_number')"
              :inputOptions="{
                    'autocomplete':'on',
                    'autofocus': false,
                    'aria-describedby': '',
                    'id':'',
                    'maxlenght':25,
                    'name': 'telephone',
                    'showDialCode':false,
                    'placeholder': $t('registration.phone_number'),
                    'readonly': false,
                    'required': false,
                    'tabindex': 0,
                    'type': 'tel',
                    'styleClasses': ''
                  }"
              @validate="telValidate"
          ></vue-tel-input>
        </div>
        <v-text-field v-else v-model='val' :rules="rules"/>
        <div @click="save" style="margin-left: 10px; margin-top: 5px">
          <i class="fas fa-save" style="font-size: 1.5em;"/>
        </div>
        <div @click="edited=false; $emit('update:value',temp); val=temp" style="margin-left: 10px; margin-top: 5px">
          <i class="fas fa-close" style="font-size: 1.5em;"/>
        </div>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "EditableText",
  props: {
    value: String,
    type: String,
    rules: Array,
    disabled: Boolean
  },
  emits: ['update:value'],
  data() {
    return {
      edited: false,
      valid:false,
      telValid: {},
      val: this.value.slice(),
      temp: this.value.slice()
    }
  },
  methods: {
    telValidate(telnumber) {
      this.telValid = telnumber
    },
    async save() {
      if ((this.type !== 'phone' || (this.telValid.valid !== false && this.telValid.formatted === this.val)) && (this.rules == null || this.valid)) {
        this.temp = this.val;
        this.$emit('update:value', this.val);
        this.edited = false;
        this.$emit('onSaved')
      }
    }
  }
}
</script>

<style scoped>
.teldiv {
  max-width: calc(100% - 58px);
  margin-bottom: 20px;
  color: black !important;
}

.tel-error:after {
  margin-left: 16px;
  content: attr(data-content);
  display: contents !important;
  font-size: 12px;
  color: rgb(var(--v-theme-error));
}
.v-field__input {
  color: inherit;
  display: flex;
  flex-wrap: wrap;
  letter-spacing: 0.009375em;
  opacity: var(--v-high-emphasis-opacity);
  min-height: var(--v-input-control-height, 56px);
  -webkit-padding-start: var(--v-field-padding-start);
  padding-inline-start: var(--v-field-padding-start);
  -webkit-padding-end: var(--v-field-padding-end);
  padding-inline-end: var(--v-field-padding-end);
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
}
.v-field__field {
  flex: 1 0;
  grid-area: field;
  position: relative;
  align-items: flex-start;
  display: flex;
  height: 35px;
}
</style>