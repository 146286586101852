import makeCrud from '../crud';
import httpReq from '../request';

const VEHICLE = 'vehicle';
const crud = new makeCrud(VEHICLE);

var config = {
    "headers": {
        "Authorization": "Basic " + localStorage.getItem('token')
    }
};

const index = {
    save: (data) => {
        return crud.save(data)
    },
    update: (data) => {
        return crud.update(data)
    },
    delete: (id) => {
        return crud.delete(id)
    },
    findById: (id) => {
        return crud.findById(id)
    },
    findAllByUserId: async () => {
        var user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.get('/api/vehicle/search/findAllByUserId?userId=' + user.id, config)).data._embedded.vehicle;
    },
    findAllByUserIdAndType: async (type) => {
        var user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.get('/api/vehicle/search/findAllByUserIdAndType?userId=' + user.id + "&type=" + type, config)).data._embedded.vehicle;
    },
}

export default index;