import MapComponents from "@/components/mapComponents";

var actions = {
    geoJsonToFeatures(geoJson) {
        let features = geoJson.features;
        features = features.map((it) => {
            const myStyle = {
                "color": it.properties.stroke,
                "weight": it.properties["stroke-width"],
                "opacity": it.properties["stroke-opacity"]
            };
            return window.L.geoJSON(it, {
                style: myStyle,
                pointToLayer: function (feature, latlng) {
                    return window.L.marker(latlng, {icon: MapComponents.icon({color:feature.properties["marker-color"]})});
                }
            })
        });
        return features;
    }
}

export default actions;