<template>
  <div>
    <v-dialog
        v-model="resultDialogData"
        class="w-90"
    >
      <v-card class="overflow-auto d-flex align-items-center text-center col-12 dialog-card">
        <v-card-title>{{ $t('grouping_routes.history_log.result') }}</v-card-title>
        <i class="fas fa-window-close" style="font-size: x-large;
              right: 5px;
              top: 5px;
              position: absolute;
              cursor: pointer"
           @click="resultDialogData=null"/>
        <div class="w-100 pa-4"
             id="resultDialog">
          <map-container ref="resultMap" class="pl-2" style="height: 350px"/>
          <v-row v-if="resultDialogData" class="p-3">
            <div v-for="vehicleId in Object.keys(resultDialogData.waypoints)" v-bind:key="vehicleId"
                 class="p-1 col-lg-4 col-md-6 col-sm-12">
              <div
                  class="border-white bg-purple justify-content-center align-items-center d-flex overflow-auto p-0 m-0 position-relative"
                  style="border-width: 0; border-style: solid; flex-direction: column;">
                <a class="d-flex w-100 justify-space-between pl-4 pr-4">
                  <a :href="createVehicleRouteUrl(vehicleId)" target="_blank" class="text-light-blue-accent-1 pr-12"
                     style="width: auto; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{
                      createVehicleRouteUrl(vehicleId)
                    }}</a>
                  <a style="cursor: copy; white-space: nowrap"
                     :class="'text-end '
                  + (copiedVehicleId === vehicleId ? ' text-light-green-accent-1' : ' text-light-blue-accent-1')"
                     @click="copyVehicleRouteUrl(vehicleId)">
                    {{ $t('grouping_routes.copy_link') }} <i class="fas fa-link"/>
                  </a>
                </a>
                <v-card-title class="white--text"
                              style="margin-top: 0 !important; width: 100%; display: flex; justify-content: space-between; align-items: center;">
                  <v-avatar size="56" style="background: white">
                    <v-img height="40"
                           alt="araç"
                           src="@/assets/template/images/bus.png"/>
                  </v-avatar>
                  <span class="ml-3">
                {{ getVehicleFromId(vehicleId).plate }}
              </span>
                </v-card-title>
                <v-card-subtitle class="bg-white w-100">
                  {{ $t('grouping_routes.description') }}: {{
                    getVehicleFromId(vehicleId).description
                  }}
                  <br/>
                  {{ $t('grouping_routes.capacity') }}: {{ getVehicleFromId(vehicleId).capacity }}
                  {{
                    getVehicleFromId(vehicleId).type === 0 ? $t('grouping_routes.pieces') : $t('grouping_routes.desi')
                  }}
                </v-card-subtitle>
              </div>
              <v-card-subtitle class="border bg-white w-auto overflow-auto" style="height: 100px">
                <div v-for="waypoint in resultDialogData.waypoints[vehicleId]" v-bind:key="waypoint.id">
                  <i class="mdi mdi-chevron-right text-purple"/> {{ waypoint.title }}
                </div>
              </v-card-subtitle>
            </div>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-table class="border" v-if="transactions.length>0">
      <thead>
      <tr class="bg-grey-darken-3">
        <th class="text-left">
          {{ $t('grouping_routes.history_log.details') }}
        </th>
        <th class="text-left">
          {{ $t('grouping_routes.history_log.type') }}
        </th>
        <th class="text-left">
          {{ $t('grouping_routes.history_log.status') }}
        </th>
        <th class="text-left">
          {{ $t('grouping_routes.history_log.amount') }}
        </th>
        <th class="text-left">
          {{ $t('grouping_routes.history_log.message') }}
        </th>
        <th class="text-left">
          {{ $t('grouping_routes.history_log.actions') }}
        </th>
      </tr>
      </thead>
      <tbody v-for="trans in transactions.slice((page-1)*10,page*10)" class="border"
             :key="trans.id">
      <tr>
        <td>
          <div v-if="trans.type === 0">
            <i v-if="expanded !== trans" class="fas fa-chevron-circle-down" style="cursor: pointer"
               @click="expandThis(trans)"/>
            <i class="fas fa-chevron-circle-up" style="cursor: pointer" v-else @click="expanded=null"/>
          </div>
        </td>
        <td>{{
            trans.type === 0 ? $t('panel.menu.grouping_routes') :
                (trans.type === 1 ? 'Dizayn Oluşturuldu' :
                    (trans.type === 2 ? 'Dizayn Güncellendi' :
                        (trans.type === 3 ? 'Dizayn Silindi' : '')))
          }}
        </td>
        <td :class="trans.success ? 'text-green' : 'text-red'">
          {{
            trans.success ? $t('grouping_routes.history_log.successful') : $t('grouping_routes.history_log.unsuccessful')
          }}
        </td>
        <td>{{ trans.value }} {{ $t('grouping_routes.history_log.credit') }}</td>
        <td>
          {{
            trans.message ? trans.message : (trans.success ? $t('grouping_routes.history_log.successful') : $t('grouping_routes.history_log.unsuccessful'))
          }}
        </td>
        <td class="d-flex flex-row">
          <v-btn v-if="trans.type === 0" @click="resultDialog(trans)" class="m-1 text-dark">{{
              $t('grouping_routes.history_log.result')
            }}
          </v-btn>
        </td>
      </tr>
      <tr v-if="expanded === trans">
        <td colspan="12" style="max-width: 0;">
          <map-container ref="centerMap" style="height: 200px; width: 200px; float: left; margin-top: 10px;"/>
          <div style="width: calc(100% - 210px); display: block; float: right; padding-bottom: 10px">
            <div
                style="display: -webkit-box; -webkit-line-clamp: 1; line-clamp: 1;  -webkit-box-orient: vertical; overflow: hidden">
              <v-card-title>{{ $t('grouping_routes.waypoints') }}</v-card-title>
              <div style="padding-left: 1rem">
                <v-chip v-for="way in expanded.request.waypoints" :key="way.id">{{ way.title }}</v-chip>
              </div>
            </div>
            <div
                style="display: -webkit-box; -webkit-line-clamp: 1; line-clamp: 1;  -webkit-box-orient: vertical; overflow: hidden">
              <v-card-title>{{ $t('grouping_routes.vehicles') }}</v-card-title>
              <div style="padding-left: 1rem">
                <v-chip v-for="way in expanded.request.vehicles" :key="way.id">{{ way.plate }}</v-chip>
              </div>
            </div>
            <div
                style="display: -webkit-box; -webkit-line-clamp: 1; line-clamp: 1;  -webkit-box-orient: vertical; overflow: hidden">
              <v-card-title>{{ $t('grouping_routes.use_all_vehicles') }}?</v-card-title>
              <v-card-subtitle>
                {{
                  (useAllVehicles ? $t('grouping_routes.all_vehicles_will_be_used') : $t('grouping_routes.the_required_number_of_vehicles_will_be_used')) + '.'
                }}
              </v-card-subtitle>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </v-table>
    <v-row class="m-4 d-flex justify-content-center" v-else>
      <v-card-subtitle>{{ $t('history_logs.there_are_no_data') }}</v-card-subtitle>
    </v-row>
    <v-pagination
        v-model="page"
        :length="transactions.length/10+1"
        rounded="circle"
    ></v-pagination>
  </div>
</template>

<script>
import actions from "@/api/transaction";
import MapContainer from "@/components/MapContainer";
import MapComponents from "@/components/mapComponents";
import GeoJson from "@/constaints/geoJson";

const L = window.L;
export default {
  name: "HistoryLog",
  components: {MapContainer},
  data: () => ({
    copiedVehicleId: null,
    page: 1,
    transactions: [],
    expanded: null,
    resultDialogData: null
  }),
  methods: {
    getVehicleFromId(vehicleId) {
      return this.resultDialogData.vehicles.find(it => it.entity.id === Number(vehicleId)).entity
    },
    createVehicleRouteUrl(vehicleId) {
      let baseUrl = "https://www.google.com/maps/dir/?api=1";
      let origin = this.resultDialogData.center;
      let url = baseUrl + "&origin=" + origin + "&destination=" + origin + "&travelmode=driving&waypoints=";
      this.resultDialogData.waypoints[vehicleId].forEach(it => {
        url += (it.coordinate[0] + "," + it.coordinate[1]) + "|";
      });
      url = url.slice(0, url.length - 2);
      return url;
    },
    copyVehicleRouteUrl(vehicleId) {
      let url = this.createVehicleRouteUrl(vehicleId);
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(url);
      } else {
        this.unsecuredCopyToClipboard(url);
      }
      this.copiedVehicleId = vehicleId;
      setTimeout((() => {
        this.copiedVehicleId = null;
      }), 2000)
    },
    unsecuredCopyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.getElementById("resultDialog").appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        // eslint-disable-next-line no-empty
      } catch (err) {
      }
      document.getElementById("resultDialog").removeChild(textArea);
    },
    resultDialog(trans) {
      try {
        this.resultDialogData = JSON.parse(trans.result);
        const lat = JSON.parse(trans.request).center.coordinate[0];
        const lon = JSON.parse(trans.request).center.coordinate[1];
        this.resultDialogData.center = lat + "," + lon
        const features1 = GeoJson.geoJsonToFeatures(this.resultDialogData.geoJson);
        setTimeout(() => {
          features1.forEach(it => {
            it.addTo(this.$refs.resultMap.map);
          })
          const group = new L.featureGroup(features1);
          this.$refs.resultMap.map.fitBounds(group.getBounds());
        }, 100);
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
    async getTransactions() {
      this.transactions = (await actions.findAllByUserId(JSON.parse(localStorage.getItem("user")).id)).sort(function (a, b) {
        return new Date(b.date) - new Date(a.date)
      });
    },
    expandThis(trans) {
      const that = this;
      this.expanded = trans;
      try {
        this.expanded.request = JSON.parse(this.expanded.request)
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
      setTimeout(() => {
        let marker = window.L.marker(that.expanded.request.center.coordinate, {
          icon: MapComponents.icon(),
        });
        that.$refs.centerMap[0].addMarkers([marker])
      }, 300)
    }
  },
  mounted() {
    this.getTransactions();
  }
}
</script>

<style scoped>

</style>