import httpReq from '../request';

const saveConfig = {
    'headers': {'Content-Type': 'application/json'},
};

const index = {
    save: async (data,title) => {
        const user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.post("/indoor/save/" + title + "/" + user.username + "/" + user.accessKey, data, saveConfig)).data
    },
    update: async (data,id) => {
        const user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.post("/indoor/update/" + id + "/" + user.username + "/" + user.accessKey, data, saveConfig)).data
    },
    delete: async (id) => {
        const user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.get("/indoor/delete/" + id + "/" + user.username + "/" + user.accessKey)).data
    },
    findAllByUserId: async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.get("/indoor/findAll/" + user.username + "/" + user.accessKey)).data
    },
}

export default index;