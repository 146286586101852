export default {
  "header": {
    "menu": {
      "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
      "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage Areas"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTER"])},
    "user_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER PANEL"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGOUT"])},
    "whatsapp_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp Support"])}
  },
  "home": {
    "intro": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Focus on Your Business"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave the mapping and routing work to us. You focus on growing your business."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in Touch"])}
    },
    "companies": {
      "references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted by companies like"])}
    },
    "services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
      "description": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our services; It offers solutions for everyone from developers to end users."])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies that need maps or routing can access our services through our website. Software developers can easily integrate our services into their own systems with API support."])}
      },
      "services": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task Grouping by Route"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It automatically groups the points to be visited by your vehicles and matches them with the appropriate vehicles. This saves fuel and time. It can be used in many areas such as passenger transportation, cargo distribution and collection, and goods supply."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With API support, you can easily use our services in your own mobile, desktop or web software."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Prices"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will get access to advanced features at low fees. With the prepaid credit system, you can use the credits you have loaded into your account for the service you want. You will not exceed the quota and you will not face high bills."])}
        }
      }
    }
  },
  "notfound": {
    "goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO BACK HOME"])}
  },
  "mail": {
    "mail_verification": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To verify your e-mail address, enter the 6-digit code below in the verification field. If you did not perform this operation, you do not need to take any action."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SayberDATA E-mail Verification"])}
    }
  },
  "sms": {
    "sms_verification": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SayberDATA account verification code: "])}
    }
  },
  "registration": {
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL"])},
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CORPORATE"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail Address"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTER"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration completed successfully. You can login."])},
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "username_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username is already in use"])},
    "email_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail is already in use"])}
  },
  "login": {
    "user_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER LOGIN"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
    "username_or_mail_or_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username/E-mail/Phone"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully logged in"])},
    "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the information you entered"])}
  },
  "panel": {
    "menu": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
      "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
      "grouping_routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouping Routes"])},
      "history_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History Log"])},
      "indoor_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indoor Design"])}
    },
    "account_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Balance"])}
  },
  "profile": {
    "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Account"])},
    "corporate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Account"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
  },
  "grouping_routes": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLOSE"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "route_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Route Details"])},
    "use_all_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use all vehicles"])},
    "all_vehicles_will_be_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All vehicles will be used"])},
    "the_required_number_of_vehicles_will_be_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The required number of vehicles will be used"])},
    "select_the_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the origin(Warehouse, center, etc.)"])},
    "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles"])},
    "waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waypoints"])},
    "grouping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouping"])},
    "editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
    "select_transportation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select transportation type"])},
    "transportation_types": {
      "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as Pieces(Passenger etc.)"])},
      "desi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as Desi(Cargo etc.)"])}
    },
    "add_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD VEHICLE"])},
    "vehicle_dialog": {
      "new_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW VEHICLE"])},
      "saved_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVED VEHICLE"])},
      "plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plate"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
      "save_to_database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save to database"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVE"])},
      "select_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select vehicles"])},
      "there_are_no_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no vehicles"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD"])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
    "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieces"])},
    "desi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desi"])},
    "waypoint_dialog": {
      "new_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW WAYPOINT"])},
      "saved_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVED WAYPOINT"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "add_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD WAYPOINT"])},
      "coordinate_latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate(Latitude)"])},
      "coordinate_longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate(Longitude)"])},
      "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Quantity(Number or Desi)"])},
      "save_to_database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save to database"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVE"])},
      "select_waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select waypoints"])},
      "there_are_no_waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no waypoints"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD"])}
    },
    "waypoints_not_less_than_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of waypoints should not be less than the number of vehicles"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOVE"])},
    "select_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Waypoint"])},
    "select_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Vehicle"])},
    "substitution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUBSTITUTION"])},
    "substitute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUBSTITUTE"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
    "the_capacity_of_one_vehicles_is_insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The capacity of one of the vehicles is insufficient for this replacement"])},
    "insufficient_capacity_of_the_selected_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient capacity of the selected vehicle"])},
    "history_log": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
      "unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsuccessful"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])}
    }
  },
  "history_logs": {
    "there_are_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no data"])}
  },
  "database": {
    "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles"])},
    "waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waypoints"])},
    "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD NEW"])},
    "vehicle": {
      "plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plate"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
      "add_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD VEHICLE"])},
      "transportation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transportation Type"])},
      "transportation_types": {
        "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as Pieces(Passenger etc.)"])},
        "desi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as Desi(Cargo etc.)"])}
      },
      "there_are_no_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no vehicles"])}
    },
    "waypoint": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "add_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD WAYPOINT"])},
      "coordinate_latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate(Latitude)"])},
      "coordinate_longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinate(Longitude)"])},
      "there_are_no_waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no waypoint"])},
      "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load Quantity(Number or Desi)"])}
    },
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVE"])}
  },
  "rules": {
    "cannot_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This area cannot empty"])},
    "min_two_char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must contain a minimum of 2 characters"])},
    "cannot_include_special_char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must not contain special characters"])},
    "mail_is_not_compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is not compatible"])},
    "invalid_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number"])},
    "passwords_does_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords does not match"])}
  },
  "stepper": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEXT"])}
  },
  "selector_map": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Results"])}
  },
  "vehicle": {
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle saved successfully."])},
    "plate_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plate already exist"])}
  },
  "verification": {
    "verify_at_least_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify at least one of the following information:"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
    "remaining_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Time"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERIFY"])},
    "verify_l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "verify_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify E-mail"])},
    "verify_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Phone"])},
    "verify_phone_reward_1000_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can earn 1000 credits by verifying your phone number."])},
    "user_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USER PANEL"])}
  }
}