import httpReq from './request'

var config = {
    "headers": {
        "Authorization": "Basic " + localStorage.getItem('token')
    }
};

var saveConfig = {
    'headers': {'Content-Type': 'application/json', "Authorization": "Basic " + localStorage.getItem('token')},
}

export default function (entity_name) {

    return {
        findAllPageable(data, projection) {
            var sort = data.sort ? data.sort : "id,desc"
            var path;
            if (data) {
                path = `/api/${entity_name}/search?page=${data.page}&size=${data.size}&sort=${sort}` + (projection ? projection : ('&projection=default'
                    + entity_name.charAt(0).toUpperCase() + entity_name.slice(1) + 'Projection'));
            } else {
                path = `/api/${entity_name}/search?sort=${sort}` + (projection ? projection : ('&projection=default'
                    + entity_name.charAt(0).toUpperCase() + entity_name.slice(1) + 'Projection'));
            }
            return httpReq.get(path, config)
        },
        async pageSize() {
            var path = `/api/${entity_name}`;
            return (await httpReq.get(path, config)).data.page.totalElements;
        },

        save(data) {
            return httpReq.post(`/api/${entity_name}/`, data, saveConfig);
        },

        update(data) {
            return httpReq.put(`/api/${entity_name}/${data.id}`, data, config);
        },

        delete(id) {
            return httpReq.delete(`/api/${entity_name}/${id}`, config);
        },

        findById(id, projection) {
            return httpReq.get(`/api/${entity_name}/${id}` + (projection ? projection : ('?projection=default'
                + entity_name.charAt(0).toUpperCase() + entity_name.slice(1) + 'Projection')), config)
        },
        countAll() {
            return httpReq.get(`/api/${entity_name}/search/countAll`, config)
        }
    }
}
