import httpReq from '../request';

var saveConfig = {
    'headers': {'Content-Type': 'application/json'},
}

const grouping = {
    grouping: async (data) => {
        const user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.post("/grouping/" + user.username + "/" + user.accessKey, data, saveConfig)).data
    },
    route: async (data) => {
        const user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.post("/route/" + user.username + "/" + user.accessKey, data, saveConfig)).data
    },
}

export default grouping;