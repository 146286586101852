<template>
  <div>
  İstatistikler ve daha fazlası çok yakında...
  </div>
</template>

<script>
export default {
  name: "DashboardContainer"
}
</script>

<style scoped>

</style>