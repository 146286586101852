<template>
  <div>
    <div class="p-5" style="min-height: calc(100% - 300px); width: 100%;">
      <v-progress-circular
          v-if="loading"
          indeterminate
          color="purple"
      ></v-progress-circular>
      <v-card v-else-if="notVerified" class="p-4">
        {{$t('verification.verify_at_least_one')}}
        <v-row class="m-0">
          <v-col>
            <v-row class="m-0 justify-space-between">
              <div class="d-flex">
                <span>{{ $t('verification.email') }}:</span>
                <editable-text v-model:value="email" :disabled="mailVerified" :rules="rules.emailRules"
                               @onSaved="onSavedEmail"
                               style="margin-left:20px; width: 250px; text-align: center"/>
              </div>
              <otp-component :text="$t('verification.verify_email')" :verified="mailVerified" :verified-text="$t('verification.verified')"
                             :time-text="$t('verification.remaining_time')" :button-text="$t('verification.verify')" :second="$t('verification.second')"
                             :on-verified="onMailVerified" time="180" :on-code-send="sendMail"/>
            </v-row>
            <v-divider/>
            <v-row class="m-0 justify-space-between">
              <div class="d-flex">
                <span>{{ $t('verification.phone') }}:</span>
                <editable-text v-model:value="tel" :disabled="phoneVerified" :rules="rules.nameRules"
                               @onSaved="onSavedTel"
                               style="margin-left:20px; width: 250px; text-align: center"/>
              </div>
              <otp-component :text="$t('verification.verify_phone')" :verified="phoneVerified" :verified-text="$t('verification.verified')"
                             :time-text="$t('verification.remaining_time')" :button-text="$t('verification.verify')" :second="$t('verification.second')"
                             :on-verified="onPhoneVerified" time="120" :on-code-send="sendSMS"/>
            </v-row>
          </v-col>
        </v-row>
        <v-card-subtitle class="mt-1">* {{ $t('verification.verify_phone_reward_1000_credit') }}</v-card-subtitle>
        <v-row class="m-2 justify-content-center">
          <v-btn class="bg-purple" :disabled="!phoneVerified && !mailVerified" @click="reloadPage()">{{ $t('verification.user_panel') }}</v-btn>
        </v-row>
      </v-card>
      <v-row v-else style="min-height: calc(100vh - 375px); flex-wrap: nowrap; overflow: hidden;">
        <div class="sidebar open">
          <i class='bx bx-menu'></i>
          <i class='bx bx-arrow-from-right'></i>
          <div class="logo-details">
            <span class="logo_name">{{ $t('header.user_panel') }}</span>
          </div>
          <v-card-subtitle class="text-center text-white">
            <div class="name">{{ user.name + " " + user.surname }}</div>
            <div class="balance">{{$t('panel.account_balance')}}: <span class="text-amber-lighten-2 fw-bold balance-value">{{user.credit}}<i class="mdi mdi-hand-coin"/></span></div>
          </v-card-subtitle>
          <ul class="nav-links">
            <li v-for="item in items" v-bind:key="item.title">
              <a class="iocn-link arrow" @click="item.click">
                <a>
                  <i :class='item.icon'></i>
                  <span class="link_name">{{ $t(item.title) }}</span>
                </a>
                <a>
                  <i v-if="item.submenu" class='bx bxs-chevron-down arrow1'></i>
                </a>
              </a>
              <ul v-if="item.submenu" class="sub-menu">
                <li v-for="sub in item.submenu" v-bind:key="sub.title">
                  <a @click="sub.click">
                    {{ $t(sub.title) }}
                  </a></li>
              </ul>
            </li>
          </ul>
        </div>
        <section class="home-section card">
          <div class="home-content">
            <component class="overflow-hidden" :is="panelView"/>
          </div>
        </section>
      </v-row>
    </div>
  </div>
</template>

<script>

import DashboardContainer from "@/pages/panel/container/DashboardContainer";
import ProfileContainer from "@/pages/panel/container/ProfileContainer";
import HistoryLog from "@/pages/panel/container/HistoryLog";
import GroupingRouteContainer from "@/pages/panel/container/GroupingRouteContainer";
import DatabaseContainer from "@/pages/panel/container/DatabaseContainer";
import EditableText from "@/components/EditableText";
import rules from "@/constaints/rules";
import userCrud from "@/api/user";
import OtpComponent from "@/components/OTPComponent";
import user from "@/api/user";
import reward from "@/api/reward";
import IndoorContainer from "@/pages/panel/container/IndoorContainer.vue";

export default {
  name: "UserPanel",
  components: {OtpComponent, EditableText},
  data() {
    return {
      rules: rules.userRules,
      panelView: DashboardContainer,
      user: JSON.parse(localStorage.getItem("user")),
      loading: true,
      email: null,
      tel: null,
      drawer: true,
      notVerified: false,
      phoneVerified: false,
      mailVerified: false,
      items: [
        {
          title: 'panel.menu.dashboard', icon: 'bx bxs-dashboard', click: () => {
            this.panelView = DashboardContainer
          }
        },
        {
          title: 'panel.menu.my_account',
          icon: 'bx bxs-user',
          submenu: [{
            title: 'panel.menu.profile', click: () => {
              this.panelView = ProfileContainer
            }
          },]
        },
        {
          title: 'panel.menu.tools', icon: 'fas fa-tools', submenu: [
            {
              title: 'panel.menu.grouping_routes', click: () => {
                this.panelView = GroupingRouteContainer
              }
            },
            {
              title: 'panel.menu.indoor_design', click: () => {
                this.panelView = IndoorContainer
              }
            },
            {
              title: 'panel.menu.history_log', click: () => {
                this.panelView = HistoryLog
              }
            }
          ]
        },
        {
          title: 'panel.menu.database', icon: 'fas fa-database', click: () => {
            this.panelView = DatabaseContainer
          }
        },
        {title: 'panel.menu.logout', icon: 'bx bxs-log-out', click: this.logout},
      ],
      mini: true,
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      window.location.replace(window.location.origin);
    },
    checkLocation() {
      const href = window.location.href;
      if (href.includes("#")) {
        const split = href.split("#");
        const location = split[1];
        switch (location) {
          case "profile":
            this.panelView = ProfileContainer;
            break;
          case "settings":
            this.panelView = HistoryLog;
            break;
          case "grouping":
            this.panelView = GroupingRouteContainer;
            break;
          default:
            this.panelView = DashboardContainer

        }
      }
    },
    async onSavedEmail() {
      var user = JSON.parse(localStorage.getItem("user"));
      user.email = this.email;
      localStorage.setItem("user", JSON.stringify(user));
      await userCrud.update(user);
    },
    async onSavedTel() {
      const user = JSON.parse(localStorage.getItem("user"));
      user.phone = this.tel;
      localStorage.setItem("user", JSON.stringify(user));
      await userCrud.update(user);
    },
    sendSMS(code) {
      let template = this.$t('sms.sms_verification.body') + code;
      user.verificationSMS(template)
    },
    sendMail(code) {
      let template = this.$t('mail.mail_verification.body');
      user.verificationMail(template, this.$t('mail.mail_verification.subject'), code)
    },
    async onPhoneVerified() {
      var user1 = JSON.parse(localStorage.getItem("user"));
      user1.phoneVerified = true;
      let result = await reward.findByUserIdAndKey("phone_verification").catch(() => {
      });
      if (!result) {
        user1.credit = (user1.credit ? user1.credit : 0) + 1000;
        const reward1 = {
          user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
          key: "phone_verification",
          value: 1000
        }
        await reward.save(reward1);
      }
      await user.update(user1);
      this.phoneVerified = true;
      localStorage.setItem("user", JSON.stringify(user1));
    },
    onMailVerified() {
      var user1 = JSON.parse(localStorage.getItem("user"));
      user1.emailVerified = true;
      user.update(user1);
      this.mailVerified = true;
      localStorage.setItem("user", JSON.stringify(user1));
    },
    reloadPage() {
      window.location.reload();
    }
  },
  beforeMount() {
    this.checkLocation();
  },
  mounted() {
    var user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      window.location.replace(window.location.origin + "/login");
    } else {
      this.loading = false;
      if (!user.emailVerified && !user.phoneVerified) {
        this.email = user.email;
        this.tel = user.phone;
        this.notVerified = true;
      } else {
        setTimeout(function () {
          var arrow = document.querySelectorAll(".arrow");
          for (var i = 0; i < arrow.length; i++) {
            arrow[i].addEventListener("click", (e) => {
              let arrowParent = e.target.parentElement;//selecting main parent of arrow
              let arrowParent1 = e.target.parentElement.parentElement;//selecting main parent of arrow
              let arrowParent2 = e.target.parentElement.parentElement.parentElement;//selecting main parent of arrow
              arrowParent.classList.toggle("showMenu");
              arrowParent1.classList.toggle("showMenu");
              arrowParent2.classList.toggle("showMenu");
            });
          }
          let sidebar = document.querySelector(".sidebar");
          let sidebarBtn = document.querySelector(".bx-menu");
          let sidebarBtn2 = document.querySelector(".bx-arrow-from-right");
          sidebarBtn.addEventListener("click", () => {
            sidebar.classList.toggle("close");
          });
          sidebarBtn2.addEventListener("click", () => {
            sidebar.classList.toggle("close");
          });
        }, 300)
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/template/css/sidebar.css";
</style>