import findIntersections from "sweepline-intersections";

const L = window.L;
export default {
    doorCheck: function (latlng, layers) {
        const result = {
            success: false,
            parent: null
        }
        const circle = L.circle(latlng, 2, {
            fillColor: 'none',
            color: 'blue',
            weight: 6,
            opacity: 0.3
        });
        const featureCollection2 = {
            "type": "FeatureCollection", "features": [
                layers[0].toGeoJSON(),
                L.PM.Utils.circleToPolygon(circle, 60).toGeoJSON()
            ]
        }
        const intersections = findIntersections(featureCollection2, true);
        const roomIntersections = [];
        layers.slice(1).filter(it => it.properties && it.properties.isRoom).forEach(it => {
            const featureCollection3 = {
                "type": "FeatureCollection", "features": [
                    it.toGeoJSON(),
                    L.PM.Utils.circleToPolygon(circle, 60).toGeoJSON()
                ]
            }
            const intersections2 = findIntersections(featureCollection3, true);
            if (intersections2 && intersections2.length > 0) {
                roomIntersections.push(it)
            }
        })
        if (intersections && intersections.length > 0 && roomIntersections.length === 0) {
            result.success = true;
        } else if (roomIntersections.length === 1 || roomIntersections.length === 2) {
            const polygonL = layers[0].getLatLngs()[0];
            const polygon = polygonL.map(it => [it.lat, it.lng]);
            const isIn = rayCasting([latlng.lat, latlng.lng], polygon);
            if (roomIntersections.length === 1 && isIn || roomIntersections.length === 2) {
                result.success = true;
                result.parent = roomIntersections[0]
            }
        }
        return result;
    },
    stairsCheck: function (latlng, layers) {
        const result = {
            success: false,
            parent: null
        }

        const polygonL = layers[0].getLatLngs()[0];
        const polygon = polygonL.map(it => [it.lat, it.lng]);
        const isIn = rayCasting([latlng.lat, latlng.lng], polygon);

        const parents = []
        layers.slice(1).filter(it => it.properties && it.properties.isRoom).forEach(it => {
            const polygonL = it.getLatLngs()[0];
            const polygon = polygonL.map(it => [it.lat, it.lng]);
            const isIn = rayCasting([latlng.lat, latlng.lng], polygon);
            if (isIn) {
                parents.push(it)
            }
        });
        if (parents.length > 0 || isIn) {
            result.success = true;
        }
        return result;
    },
    escalatorCheck: function (latlng, layers) {
        return this.stairsCheck(latlng, layers)
    },
    elevatorCheck: function (latlng, layers) {
        return this.stairsCheck(latlng, layers)
    },
    roomCheck: function (latlngs, layers) {
        const result = {
            success: false,
            parent: null
        }
        const layer = L.polygon(latlngs, {
            style: {
                color: 'white'
            }
        })
        const featureCollection = {
            "type": "FeatureCollection", "features": [
                layer.toGeoJSON()
            ]
        }
        const selfIntersections = findIntersections(featureCollection, false)
        const featureCollection2 = {
            "type": "FeatureCollection", "features": [
                layers[0].toGeoJSON(),
                layer.toGeoJSON()
            ]
        }
        const floorIntersections = findIntersections(featureCollection2, true);
        let isInFloor = true;
        layer.getLatLngs()[0].forEach(latLng => {
            const polygonL = layers[0].getLatLngs()[0];
            const polygon = polygonL.map(it => [it.lat, it.lng])
            var isIn = rayCasting([latLng.lat, latLng.lng], polygon)
            if (!isIn) {
                isInFloor = false
            }
        })
        const res = findParent(layer, layers);
        result.parent = res.parent
        result.success = !((selfIntersections && selfIntersections.length > 0) ||
                ((!isInFloor && !result.parent) && (!floorIntersections || floorIntersections.length === 0)))
            && !res.roomsIntersections;
        return result;
    },
    floorCheck: function (latlngs) {
        const result = {
            success: false,
            parent: null
        }
        const layer = L.polygon(latlngs, {
            style: {
                color: 'white'
            }
        })
        const featureCollection = {
            "type": "FeatureCollection", "features": [
                layer.toGeoJSON()
            ]
        }
        const selfIntersections = findIntersections(featureCollection, false)
        result.success = !selfIntersections;
        return result;
    }
}

function rayCasting(point, polygon) {
    var n = polygon.length,
        x = point[0],
        y = point[1];


    var i, j, c = false;
    for (i = 0, j = n - 1; i < n; j = i++) {
        if (((polygon[i][1] > y) !== (polygon[j][1] > y)) &&
            (x < (polygon[j][0] - polygon[i][0]) * (y - polygon[i][1]) / (polygon[j][1] - polygon[i][1]) + polygon[i][0]))
            c = !c;
    }
    return c;
}

function findParent(layer, layers) {
    const result = {};
    const layerList = layers.filter(it => it.properties.type && it.properties.type === "room");
    const parents = [];
    if (layerList.length > 1) {
        layerList.slice(1).forEach(it => {
            const featureCollection2 = {
                "type": "FeatureCollection", "features": [
                    it.toGeoJSON(),
                    layer.toGeoJSON()
                ]
            }
            const intersections = findIntersections(featureCollection2, true);
            const inter = intersections && intersections.length > 0
            if (inter) {
                result.roomsIntersections = true;
            }
            let isInRoom = true;
            layer.getLatLngs()[0].forEach(latLng => {
                const polygonL = it.getLatLngs()[0];
                const polygon = polygonL.map(it => [it.lat, it.lng])
                var isIn = rayCasting([latLng.lat, latLng.lng], polygon)
                if (!isIn) {
                    isInRoom = false
                }
            });
            if (isInRoom) {
                parents.push(it);
            }
        })
    }
    let minArea;
    parents.forEach(parent => {
        const area = L.GeometryUtil.geodesicArea(parent.getLatLngs()[0]);
        if (!result.parent || area < minArea) {
            minArea = area;
            result.parent = parent
        }
    })
    return result;
}