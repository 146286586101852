<template>
  <v-snackbar
      v-model="snackbar"
      color="red"
      timeout="3000"
  >
    <span class="text-white"> {{ snackbarText }} </span>

    <template v-slot:actions>
      <v-btn
          color="white"
          variant="text"
          @click="snackbar = false"
      >
        KAPAT
      </v-btn>
    </template>
  </v-snackbar>
  <v-dialog
      v-model="deleteDesignDialog"
      style="max-width: 500px"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        İşlemi onaylıyor musunuz?
      </v-card-title>
      <v-card-text>
        {{ deleteDesignModel.title }} isimli, kayıtlı bina tasarımınız, tamamen silecektir. Bu işlem geri alınamaz.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="red-darken-1"
            variant="text"
            @click="deleteDesignDialog = false"
        >
          İPTAL
        </v-btn>
        <v-btn
            color="green-darken-1"
            variant="text"
            @click="deleteDesign"
        >
          ONAYLA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="validateDialog"
      style="max-width: 500px"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        İşlemi onaylıyor musunuz?
      </v-card-title>
      <v-card-text>
        Kayıtlı bina tasarımını yüklemek şuanki tasarımınızı tamamen silecektir. Bu işlem geri alınamaz.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="red-darken-1"
            variant="text"
            @click="validateDialog = false"
        >
          İPTAL
        </v-btn>
        <v-btn
            color="green-darken-1"
            variant="text"
            @click="loadSaved()"
        >
          ONAYLA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="validateClearDialog"
      style="max-width: 500px"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        İşlemi onaylıyor musunuz?
      </v-card-title>
      <v-card-text>
        Onaylarsanız kaydedilmeyen tasarımınız tamamen silinecektir. Bu işlem geri alınamaz.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="red-darken-1"
            variant="text"
            @click="validateClearDialog = false"
        >
          İPTAL
        </v-btn>
        <v-btn
            color="green-darken-1"
            variant="text"
            @click="clearAll"
        >
          ONAYLA
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog
      v-model="saveDialog"
      style="max-width: 500px"
      persistent
  >
    <v-card>
      <v-card-title class="text-h5">
        Bina Tasarımını Kaydet
      </v-card-title>
      <v-card-text>
        <span v-if="loadedDesign">
          <v-radio-group label="Lütfen seçim yapınız:" v-model="updateOrSave">
            <v-radio label="Yüklediğim tasarımı güncelle" :value="false"></v-radio>
            <v-radio label="Yeni tasarım olarak kaydet" :value="true"></v-radio>
          </v-radio-group>
        </span>
        <span v-if="updateOrSave">
          <v-text-field label="Başlık" v-model="newDesignName"
                        :rules="[v => !!v || 'Field is required']"
                        variant="underlined"></v-text-field>
        </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="red-darken-1"
            variant="text"
            @click="saveDialog = false"
        >
          İPTAL
        </v-btn>
        <v-btn
            color="green-darken-1"
            variant="text"
            @click="saveDesign"
        >
          KAYDET
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <div>
    <v-toolbar border density="compact">
      <v-toolbar-title class="ml-2 mr-2">
        <div class="">
          <v-select
              style="width: 200px; float:left"
              label="Kayıtlı Bina Tasarımları"
              :items="savedDesigns"
              v-model="selectedDesign"
              return-object
              hide-details
              item-title="title"
              variant="underlined"
              density="compact"
              no-data-text="Kayıtlı bina tasarımı bulunmuyor"
          >
            <template v-slot:item="{ item, props }">
              <v-list-item class="m-0 p-0">
                <v-list-item-action v-bind="props" class="m-0 p-0">
                  <v-btn class="text-none m-0 p-0">
                    <v-row no-gutters class="d-flex justify-space-between pl-1 pr-1" style="width: 200px" align="center">
                      <span>{{ item.title }}</span>
                      <v-chip class="m-1" @click="deleteDesignModel = item.raw; deleteDesignDialog = true;"
                              text-color="white"
                              color="red" small
                      ><i class="fas fa-trash"/></v-chip>
                    </v-row>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-select>
          <v-btn class="bg-purple p-0 pt-1 pb-1 ml-2" color="white" :disabled="!selectedDesign" style="height: auto;"
                 @click="loadSaved(true)">
            <i class="fas fa-file-import"/> <span class="ml-1" style="font-size: xx-small;">KAYITTAN <br/> YÜKLE</span>
          </v-btn>
        </div>
      </v-toolbar-title>
      <v-btn class="bg-dark mr-2" color="white" @click="validateClearDialog = true">TEMİZLE</v-btn>
      <v-btn class="bg-dark" color="white" @click="showSaveDialog">KAYDET</v-btn>
    </v-toolbar>
    <IndoorDesignMap ref="indoorRef"/>
  </div>
</template>

<script>
import IndoorDesignMap from "@/components/IndoorDesignMap.vue";
import indoorActions from "@/api/indoor"
import pCode from "@/constaints/pCode";

export default {
  name: "IndoorContainer",
  components: {IndoorDesignMap},
  data: () => ({
    savedDesigns: [],
    selectedDesign: null,
    loadedDesign: null,
    validateDialog: false,
    validateClearDialog: false,
    saveDialog: false,
    updateOrSave: true,
    newDesignName: null,
    snackbar: false,
    snackbarText: "Bir hata oluştu.",
    deleteDesignModel: null,
    deleteDesignDialog: false
  }),
  methods: {
    async getSavedDesigns() {
      const user = JSON.parse(localStorage.getItem("user"));
      this.savedDesigns = await indoorActions.findAllByUserId(user.id);
    },
    loadSaved(check) {
      let isNotEmpty = false;
      if (check) {
        Object.keys(this.$refs.indoorRef.floors).forEach((key) => {
          const floor = this.$refs.indoorRef.floors[key]
          if (floor.editableLayers && floor.editableLayers.getLayers().length > 0) {
            isNotEmpty = true;
          }
        });
      }
      if (isNotEmpty) {
        this.validateDialog = true;
      } else {
        this.$refs.indoorRef.json = this.selectedDesign.pcode;
        this.$refs.indoorRef.fromJson();
        this.validateDialog = false;
        this.loadedDesign = this.selectedDesign;
      }
    },
    clearAll() {
      this.$refs.indoorRef.floors = {
        0: {
          editableLayers: new window.L.FeatureGroup()
        }
      };
      this.$refs.indoorRef.selectedFloor = 0;
      this.$refs.indoorRef.configs();
      this.selectedDesign = null;
      this.loadedDesign = null;
      this.validateClearDialog = false;
    },
    showSaveDialog() {
      this.saveDialog = true;
    },
    saveDesign() {
      const data = this.$refs.indoorRef.json;
      let result = pCode.floorsDataFromJson(data)
      if (result.success) {
        if (this.loadedDesign && !this.updateOrSave) {
          this.loadedDesign.data = data;
          indoorActions.update(data, this.loadedDesign.id).then(() => {
            this.getSavedDesigns();
          });
          this.saveDialog = false;
        } else if (this.newDesignName && this.newDesignName.length > 2) {
          indoorActions.save(data, this.newDesignName).then(() => {
            this.getSavedDesigns();
          });
          this.saveDialog = false;
        } else {
          this.snackbarText = "Tasarım başlığı en az 3 karakterden oluşmalıdır.";
          this.snackbar = true;
        }
      } else {
        this.snackbarText = "Tasarımınız hata/hatalar içeriyor.";
        this.snackbar = true;
        this.saveDialog = false;
      }
    },
    deleteDesign() {
      if (this.deleteDesignModel && this.deleteDesignModel.id) {
        indoorActions.delete(this.deleteDesignModel.id).then(()=>{
          this.selectedDesign = null;
          this.getSavedDesigns();
        });
        this.deleteDesignDialog = false;
      }else{
        this.snackbarText = "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.";
        this.snackbar = true;
      }
    }
  },
  mounted() {
    this.getSavedDesigns();
  }
}
</script>

<style scoped>
</style>