import i18n from '@/i18n'

const {t} = i18n.global
const userRules = {
    nameRules: [
        v => !!v || t('rules.cannot_empty'),
        v => /[0-9a-zA-Z]{2,}/.test(v) || t('rules.min_two_char')
    ],
    surnameRules: [
        v => !!v || t('rules.cannot_empty'),
        v => /[0-9a-zA-Z]{2,}/.test(v) || t('rules.min_two_char')
    ],
    usernameRules: [
        v => !!v || t('rules.cannot_empty'),
        v => /[0-9a-zA-Z]{2,}/.test(v) || t('rules.min_two_char'),
        v => /^[a-zA-Z0-9''-'-_\s]{1,40}$/.test(v) || t('rules.cannot_include_special_char')
    ],
    passwordRules: [
        v => !!v || t('rules.cannot_empty'),
        v => /[0-9a-zA-Z]{2,}/.test(v) || t('rules.min_two_char'),
        v => /^[a-zA-Z0-9''-'-_\s]{1,40}$/.test(v) || t('rules.cannot_include_special_char')
    ],
    confirmPasswordRules: [
        v => v ? t('rules.passwords_does_not_match') : t('rules.cannot_empty'),
    ],
    emailRules: [
        v => !!v || t('rules.cannot_empty'),
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || t('rules.mail_is_not_compatible')
    ],
    nullRules: [
        () => true
    ]
}

export default userRules;