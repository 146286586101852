<template>
  <div>
    <v-sheet elevation="6">
      <v-tabs
          v-model="tab"
          bg-color="purple"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          fixed-tabs
      >
        <v-tab
            v-for="tab in tabs"
            :key="tab"
        >
          {{ $t(tab) }}
        </v-tab>
      </v-tabs>
    </v-sheet>
    <component
        :is="currentView[tab]"/>
  </div>
</template>

<script>
import VehicleContainer from "@/pages/panel/container/datacontainer/VehicleContainer"
import WaypointContainer from "@/pages/panel/container/datacontainer/WaypointContainer";
export default {
  name: "DatabaseContainer",
  data:()=>({
    currentView:[VehicleContainer,WaypointContainer],
    tab:null,
    tabs:[
        'database.vehicles',
        'database.waypoints'
    ]
  }),
  methods:{

  }
}
</script>

<style scoped>

</style>