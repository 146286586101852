<template>
  <div>
    <div class="p-5" style="text-align: -webkit-center;">
      <div style="max-width: 380px;">
        <v-card class="pa-5" color="white">
          <v-form ref="form" @submit.prevent="">
            <div class="text-center bg-purple mb-3 p-3 font-weight-bold">{{$t('login.user_login')}}</div>
            <v-text-field
                outlined
                v-model="username"
                :label="$t('login.username_or_mail_or_phone')"
                required
                @keyup.enter="login"
                color="purple"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="password"
                :label="$t('login.password')"
                :append-icon="!show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                required
                @keyup.enter="login"
                color="purple"
            ></v-text-field>
            <v-row>
              <div class="mx-auto w-50 mt-3">
                <v-btn color="purple" dark class="w-100" @click="login">
                  {{ $t('login.login') }}
                </v-btn>
              </div>
            </v-row>
            <v-spacer style="height: 30px"/>
            <v-alert
                v-if="alert.message"
                :type="alert.type"
                style="padding-bottom: 5px; padding-top: 5px;"
            >{{ alert.message }}
            </v-alert>
          </v-form>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import actions from "../api/user"
import {sha256} from "js-sha256";

export default {
  name: "LoginPage",
  data: () => ({
    username: null,
    password: null,
    show: false,
    alert: {
      type: "",
      message: ""
    }
  }),
  methods: {
    async login() {
      let result;
      try {
        result = await actions.login(this.username, sha256(this.password));
      } catch (err) {
        result = err;
      }
      if (result.data) {
        const user = result.data;
        const token = window.btoa(user.username + ":" + user.accessKey);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        setTimeout(function () {
          window.location.replace(window.location.origin+"/panel");
        }, 1000)
        this.alert.type = "success";
        this.alert.message = this.$t('login.success');
      } else {
        this.alert.type = "error";
        this.alert.message = this.$t('login.incorrect');
      }
    }
  }
}
</script>

<style scoped>
.switch-back {
  left: 0;
  transition: all 0.2s linear;
}

.switch-back-right {
  left: calc(50% - 12px);
}

.teldiv {
  margin-bottom: 20px;
  color: black !important;
}

.tel-error:after {
  margin-left: 16px;
  content: attr(data-content);
  display: contents !important;
  font-size: 12px;
  color: rgb(var(--v-theme-error));
}

</style>