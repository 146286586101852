<template>
  <div>
    <v-dialog
        v-model="addVehicleDialog"
        class="col-12 col-sm-12 col-md-6 col-lg-6"
    >
      <v-form v-model="addVehicleDialogValidate"
              @submit.prevent="addVehicle">
        <v-card class="overflow-hidden d-flex align-items-center text-center" style="flex-direction: column">
          <v-card-title>{{$t('database.vehicle.add_vehicle')}}</v-card-title>
          <i class="fas fa-window-close" style="font-size: x-large;
              right: 5px;
              top: 5px;
              position: absolute;
              cursor: pointer"
             @click="addVehicleDialog=false"/>
          <v-card-text class="w-75">
            <v-text-field
                density="compact"
                variant="solo"
                :label="$t('database.vehicle.plate')"
                v-model="newVehicle.plate"
                append-inner-icon="mdi-car"
                single-line
                :rules="[
                  v => !!v || 'Field is required'
                ]"
                hide-details
            ></v-text-field>
            <v-text-field
                density="compact"
                variant="solo"
                :label="$t('database.vehicle.description')"
                v-model="newVehicle.description"
                append-inner-icon="mdi-account"
                single-line
                hide-details
                class="mt-1"
            ></v-text-field>
            <v-radio-group v-model="newVehicle.type"
                           class="mt-4"
                           :label="$t('database.vehicle.transportation_type')">
              <v-radio :label="$t('database.vehicle.transportation_types.pieces')" value="0"></v-radio>
              <v-radio :label="$t('database.vehicle.transportation_types.desi')" value="1"></v-radio>
            </v-radio-group>
            <v-text-field
                density="compact"
                variant="solo"
                :label="$t('database.vehicle.capacity')"
                v-model="newVehicle.capacity"
                append-inner-icon="mdi-warehouse"
                single-line
                hide-details
                :rules="[
                    v => !!v || 'Field is required'
                  ]"
                type="number"
                class="mt-1"
            ></v-text-field>

            <v-alert
                v-if="alert.message"
                :type="alert.type"
                style="padding-bottom: 5px; padding-top: 5px;"
            >{{ alert.message }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn type="submit" color="purple" block>{{ $t('database.save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="d-flex justify-content-end">
      <v-btn color="purple" class="m-2" @click="addVehicleDialog=true">+ {{ $t('database.add_new') }}</v-btn>
    </div>
    <v-table v-if="vehicles.length>0">
      <thead>
      <tr>
        <th class="text-left">
          {{ $t('database.vehicle.plate') }}
        </th>
        <th class="text-left">
          {{ $t('database.vehicle.description') }}
        </th>
        <th class="text-left">
          {{ $t('database.vehicle.capacity') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="vehicle in vehicles.slice((page-1)*10,page*10)"
          :key="vehicle.plate">
        <td>{{ vehicle.plate }}</td>
        <td>{{ vehicle.description }}</td>
        <td>{{ vehicle.capacity + ' ' + (vehicle.type =='0' ? 'adet' : 'desi')}}</td>
      </tr>
      </tbody>
    </v-table>
    <v-row class="m-4 d-flex justify-content-center" v-else>
      <v-card-subtitle>{{ $t('database.vehicle.there_are_no_vehicles') }}</v-card-subtitle>
    </v-row>
    <v-pagination
        v-model="page"
        :length="vehicles.length/10+1"
        rounded="circle"
    ></v-pagination>
  </div>
</template>

<script>
import actions from "@/api/vehicle"

export default {
  name: "VehicleContainer",
  data: () => ({
    page: 1,
    addVehicleDialog: false,
    addVehicleDialogValidate: false,
    newVehicle: {
      user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
      plate: null,
      description: null,
      type: "0",
      capacity: null
    },
    alert: {
      type: "",
      message: ""
    },
    vehicles: []
  }),
  methods: {
    async addVehicle() {
      if (this.addVehicleDialogValidate) {
        let result;
        try {
          result = await actions.save(this.newVehicle);
        } catch (err) {
          result = err;
        }
        if (result.data) {
          this.alert.type = "success";
          this.alert.message = this.$t('vehicle.success');
          this.newVehicle = {
            user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
            plate: null,
            description: null,
            type: "0",
            capacity: null
          }
          this.addVehicleDialog = false;
          this.getVehicles();
        } else if (result.response) {
          const errorMessage = this.getMessageFromResponse(result.response.data.message);
          this.alert.type = "error";
          this.alert.message = errorMessage;
        } else {
          this.alert.type = "error";
          this.alert.message = this.$t('vehicle.something_went_wrong');
        }
      }
    },
    async getVehicles() {
      this.vehicles = await actions.findAllByUserId(JSON.parse(localStorage.getItem("user")).id);
    },
    getMessageFromResponse(response) {
      if (response.includes("vehicles_vehicle_plate")) {
        return this.$t('vehicle.plate_exist');
      }  else {
        return this.$t('vehicle.something_went_wrong');
      }
    },
  },
  mounted() {
    this.getVehicles();
  }
}
</script>

<style scoped>

</style>