<template>
  <div>
    <v-progress-circular
        v-if="!user"
        indeterminate
        color="purple"
    ></v-progress-circular>
    <div v-else class="container">
      <v-card v-if="verifyPanel" class="p-4">
        {{$t('verification.verify_at_least_one')}}
        <v-row class="m-0">
          <v-col>
            <v-row class="m-0 justify-space-between">
              <div class="d-flex">
                <span>{{ $t('verification.email') }}:</span>
                <editable-text v-model:value="user.email" :disabled="user.emailVerified" :rules="rules.emailRules"
                               @onSaved="onSaved('mail')"
                               style="margin-left:20px; width: 250px; text-align: center"/>
              </div>
              <otp-component :text="$t('verification.verify_email')" :verified="user.emailVerified" :verified-text="$t('verification.verified')"
                             :time-text="$t('verification.remaining_time')" :button-text="$t('verification.verify')" :second="$t('verification.second')"
                             :on-verified="onMailVerified" time="180" :on-code-send="sendMail"/>
            </v-row>
            <v-divider/>
            <v-row class="m-0 justify-space-between">
              <div class="d-flex">
                <span>{{ $t('verification.phone') }}:</span>
                <editable-text v-model:value="user.phone" :disabled="user.phoneVerified" :rules="rules.nameRules"
                               @onSaved="onSaved('phone')"
                               style="margin-left:20px; width: 250px; text-align: center"/>
              </div>
              <otp-component :text="$t('verification.verify_phone')" :verified="user.phoneVerified" :verified-text="$t('verification.verified')"
                             :time-text="$t('verification.remaining_time')" :button-text="$t('verification.verify')" :second="$t('verification.second')"
                             :on-verified="onPhoneVerified" time="120" :on-code-send="sendSMS"/>
            </v-row>
          </v-col>
        </v-row>
        <v-card-subtitle class="mt-1">* {{ $t('verification.verify_phone_reward_1000_credit') }}</v-card-subtitle>
        <v-row class="m-2 justify-content-center">
          <v-btn class="bg-purple" @click="verifyPanel = false;">{{ $t('verification.user_panel') }}</v-btn>
        </v-row>
      </v-card>
      <div v-else class="main-body">
        <div class="row gutters-sm">
          <div class="col-md-4 mb-3">
            <div class="border">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <img :src="require('@/assets/template/images/avatar7.png')" alt="user_avatar" class="rounded-circle"
                       width="150">
                  <div class="mt-3">
                    <h4>{{ user.name }} {{ user.surname }}</h4>
                    <p class="text-purple mb-1">
                      {{ user.personal ? $t('profile.personal_account') : $t('profile.corporate_account') }}</p>
                    <p class="text-muted font-size-sm">{{ user.username }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="border mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{ $t('profile.name') }}</h6>
                  </div>
                  <editable-text v-model:value="user.name" :rules="rules.nameRules" @onSaved="onSaved"/>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{ $t('profile.surname') }}</h6>
                  </div>
                  <editable-text v-model:value="user.surname" :rules="rules.surnameRules" @onSaved="onSaved"/>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{ $t('profile.email') }}</h6>
                  </div>
                  <div class="col-sm-9">
                    <editable-text v-model:value="user.email" :rules="rules.emailRules" @onSaved="onSaved('mail')"/>
                    <span @click="()=> {if(!user.emailVerified) this.verifyPanel = true;}"
                          :class="user.emailVerified ? 'text-green' : 'border p-1'"
                          :style="user.emailVerified ? '' : 'cursor: pointer'"><i v-if="user.emailVerified"
                                                                                  class="mdi mdi-check-decagram"/>{{
                        user.emailVerified ? $t('verification.verified') : $t('verification.verify_l')
                      }}</span>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-sm-3">
                    <h6 class="mb-0">{{ $t('profile.phone') }}</h6>
                  </div>
                  <div class="col-sm-9">
                    <editable-text v-model:value="user.phone" type="phone" @onSaved="onSaved('phone')"/>
                    <span @click="()=> {if(!user.phoneVerified) this.verifyPanel = true;}"
                          :class="user.phoneVerified ? 'text-green' : 'border p-1'"
                          :style="user.phoneVerified ? '' : 'cursor: pointer'"><i v-if="user.phoneVerified"
                                                                                  class="mdi mdi-check-decagram"/>{{
                        user.phoneVerified ? $t('verification.verified') : $t('verification.verify_l')
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="row gutters-sm">
                          <div class="col-sm-6 mb-3">
                            <div class="card h-100">
                              <div class="card-body">
                                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project
                                  Status</h6>
                                <small>Web Design</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>Website Markup</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>One Page</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>Mobile Template</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>Backend API</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 mb-3">
                            <div class="card h-100">
                              <div class="card-body">
                                <h6 class="d-flex align-items-center mb-3"><i class="material-icons text-info mr-2">assignment</i>Project
                                  Status</h6>
                                <small>Web Design</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>Website Markup</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 72%" aria-valuenow="72"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>One Page</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 89%" aria-valuenow="89"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>Mobile Template</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 55%" aria-valuenow="55"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <small>Backend API</small>
                                <div class="progress mb-3" style="height: 5px">
                                  <div class="progress-bar bg-primary" role="progressbar" style="width: 66%" aria-valuenow="66"
                                       aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditableText from "@/components/EditableText";
import OtpComponent from "@/components/OTPComponent";
import rules from "@/constaints/rules";
import userCrud from "@/api/user"
import user from "@/api/user";
import reward from "@/api/reward";

export default {
  name: "ProfileContainer",
  components: {EditableText, OtpComponent},
  data: () => ({
    rules: rules.userRules,
    user: null,
    verifyPanel: false
  }),
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  methods: {
    async onSaved(type) {
      if (type) {
        if (type === 'phone') {
          this.user.phoneVerified = false;
        } else if (type === 'mail') {
          this.user.emailVerified = false;
        }
      }
      await userCrud.update(this.user);
      localStorage.setItem("user", JSON.stringify(this.user))
    },
    sendSMS(code) {
      let template = this.$t('sms.sms_verification.body') + code;
      user.verificationSMS(template)
    },
    sendMail(code) {
      let template = this.$t('mail.mail_verification.body');
      user.verificationMail(template, this.$t('mail.mail_verification.subject'), code)
    },
    async onPhoneVerified() {
      var user1 = JSON.parse(localStorage.getItem("user"));
      user1.phoneVerified = true;
      let result = await reward.findByUserIdAndKey("phone_verification").catch(() => {
      });
      if (!result) {
        user1.credit = (user1.credit ? user1.credit : 0) + 1000;
        const reward1 = {
          user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
          key: "phone_verification",
          value: 1000
        }
        await reward.save(reward1);
      }
      await user.update(user1);
      this.user.phoneVerified = true;
      localStorage.setItem("user", JSON.stringify(user1));
    },
    onMailVerified() {
      var user1 = JSON.parse(localStorage.getItem("user"));
      user1.emailVerified = true;
      user.update(user1);
      this.user.emailVerified = true;
      localStorage.setItem("user", JSON.stringify(user1));
    }
  }
}
</script>

<style scoped>
.main-body {
  padding: 15px;
}

.card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}

.gutters-sm > .col, .gutters-sm > [class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.bg-gray-300 {
  background-color: #e2e8f0;
}

.h-100 {
  height: 100% !important;
}

.shadow-none {
  box-shadow: none !important;
}
</style>