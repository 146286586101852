import makeCrud from '../crud';
import httpReq from '../request';

const TRANSACTION = 'transaction';
const crud = new makeCrud(TRANSACTION);

var config = {
    "headers": {
        "Authorization": "Basic " + localStorage.getItem('token')
    }
};

const index = {
    save: (data) => {
        return crud.save(data)
    },
    update: (data) => {
        return crud.update(data)
    },
    delete: (id) => {
        return crud.delete(id)
    },
    findById: (id) => {
        return crud.findById(id)
    },
    findAllByUserId: async () => {
        var user = JSON.parse(localStorage.getItem("user"));
        return (await httpReq.get('/api/transaction/search/findAllByUserId?userId=' + user.id, config)).data._embedded.transaction;
    },
    findAllByParentId: async (parentId) => {
        return (await httpReq.get('/api/transaction/search/findAllByParentId?parentId=' + parentId, config)).data._embedded.transaction;
    },
}

export default index;