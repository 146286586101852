<template>
  <v-navigation-drawer
      v-model="drawer"
      fixed
      permanent
      right
      class="menu navbar-dark"
      :style="'margin-top:'+appBarHeight+'px'"
  >
    <v-list
        nav
        dense
    >
      <v-list-item-group
          v-model="group"
          class="navbar-nav"
          active-class="deep-purple--text text--accent-4"
      >
        <v-list-item :href="baseUrl + '#home'" @click="drawer=false" class="text-white">
          <v-list-item-title>{{ $t('header.menu.homepage') }}</v-list-item-title>
        </v-list-item>

        <v-list-item :href="baseUrl + '#services'" @click="drawer=false" class="text-white">
          <v-list-item-title>{{ $t('header.menu.services') }}</v-list-item-title>
        </v-list-item>

        <v-list-item :href="baseUrl + '#faq'" @click="drawer=false" class="text-white d-none">
          <v-list-item-title>{{ $t('header.menu.faq') }}</v-list-item-title>
        </v-list-item>

        <v-list-item :href="baseUrl + '#portfolio'" @click="drawer=false" class="text-white d-none">
          <v-list-item-title>{{ $t('header.menu.portfolio') }}</v-list-item-title>
        </v-list-item>

        <v-list-item :href="baseUrl + '#contact'" @click="drawer=false" class="text-white d-none">
          <v-list-item-title>{{ $t('header.menu.contact') }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
  <div style="z-index: 9999999; height: 30px"
       class="bg-dark w-100 position-fixed text-white text-md-center pl-6">
    <img height="20" style="cursor: pointer;" onclick="localStorage.setItem('lang','tr')" @click="$i18n.locale = 'tr'"
         :src="require('@/assets/template/images/flags/tr.png')"/>
    <v-divider
        class="m-2"
        vertical
    ></v-divider>
    <img height="20" style="cursor: pointer;" onclick="localStorage.setItem('lang','en');" @click="$i18n.locale = 'en'"
         :src="require('@/assets/template/images/flags/en.png')"/>
    <div style="right: 10px; top:0; position:fixed">
      <v-row
          align="center"
          style="margin: 0"
      >
        <v-btn
            :href="baseUrl + '/register'"
            v-if="!user"
            target="_self"
            id="app-bar-register-btn"
            color="transparent" elevation="0">
          <i class="fas fa-user-plus m-1"/>{{ $t('header.register') }}
        </v-btn>
        <v-btn
            :href="baseUrl + '/login'"
            v-if="!user"
            target="_self"
            id="app-bar-login-btn"
            color="transparent" elevation="0">
          <i class="fas fa-arrow-right m-1"/>{{ $t('header.login') }}
        </v-btn>
        <v-btn
            :href="baseUrl + '/panel'"
            v-if="user"
            target="_self"
            id="app-bar-panel-btn"
            color="transparent" elevation="0">
          <i class="fas fa-id-card m-1"/>{{ $t('header.user_panel') }}
        </v-btn>
        <v-btn
            @click="logout"
            v-if="user"
            target="_self"
            id="app-bar-logout-btn"
            color="transparent" elevation="0">
          <i class="fas fa-arrow-right m-1"/>{{ $t('header.logout') }}
        </v-btn>
      </v-row>
    </div>
  </div>
  <nav ref="appBar" style="margin-top: 30px" class="navbar navbar-expand-lg navbar-dark menu shadow fixed-top">
    <div class="container">
      <a class="navbar-brand" :href="baseUrl">
        <img :src="require('@/assets/template/images/logo.png')" alt="logo image"/>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span @click.stop="drawer = !drawer" class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link active" aria-current="page" :href="baseUrl + '#home'">{{
              $t('header.menu.homepage')
            }}</a></li>
          <li class="nav-item"><a class="nav-link" :href="baseUrl + '#services'">{{ $t('header.menu.services') }}</a>
          </li>
          <li class="nav-item d-none"><a class="nav-link" :href="baseUrl + '#faq'">{{ $t('header.menu.faq') }}</a></li>
          <li class="nav-item d-none"><a class="nav-link" :href="baseUrl + '#portfolio'">{{ $t('header.menu.portfolio') }}</a>
          </li>
          <li class="nav-item d-none"><a class="nav-link" :href="baseUrl + '#contact'">{{ $t('header.menu.contact') }}</a>
          </li>
        </ul>
        <button type="button"
                onclick="window.open('https://wa.me/send/?phone=908503078979&type=phone_number&app_absent=0','_blank')"
                class="rounded-pill btn-rounded">
          <div class="text-muted fa-xs w-100 position-absolute start-0 top-0">{{$t('header.whatsapp_support')}}</div>
          +908503078979
          <span>
            <img width="30" style="margin-top: 5px" :src="require('@/assets/template/images/whatsapp-icon.png')"/>
          </span>
        </button>
      </div>
    </div>
  </nav>
  <div :style="{'min-height':(isHome ? this.appBarHeight : this.appBarHeight)+'px'}"/>
</template>

<script>
export default {
  data: () => ({
    isHome:true,
    drawer: false,
    group: null,
    appBarHeight: 0,
    baseUrl: '',
    user: null
  }),

  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    matchHeight() {
      this.appBarHeight = this.$refs.appBar.clientHeight + 30;
    },
    logout(){
      localStorage.clear();
      window.location.replace(window.location.origin);
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.matchHeight();
    this.baseUrl = window.location.origin;
    const path = window.location.href;
    var route = path.slice(this.baseUrl.length)
    if (route.includes("#")) {
      route = route.split('#')[0]
    }
    this.isHome = route==='/'
  }
}
</script>
<style>
@import '../assets/template/css/style.css';
@import '../assets/template/css/fontawesome.css';
#app-bar-register-btn{
  height:30px;
}
#app-bar-login-btn{
  height:30px;
  margin-left: 15px;
}
@media only screen and (max-width: 450px) {
  #app-bar-register-btn{
    height:30px;
    padding: 0;
    font-size: 12px;
  }
  #app-bar-login-btn{
    height:30px;
    margin-left: 5px;
    padding: 0;
    font-size: 12px;
  }
}
</style>