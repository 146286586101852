import HomePage from '../pages/HomePage.vue'
import RegisterPage from '../pages/RegisterPage.vue'
import LoginPage from '../pages/LoginPage.vue'
import UserPanel from '../pages/panel/UserPanel.vue'

const routes = {
    '/': HomePage,
    '/register': RegisterPage,
    '/login': LoginPage,
    '/panel': UserPanel,
}
export default routes;