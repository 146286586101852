import mapComponents from "@/components/mapComponents";
import drawingRules from "@/components/indoorLib/rules"

var actions = {
    floorsDataToJson(value) {
        const newVal = {
            0: {}
        };
        Object.keys(value).forEach(key => {
            if (value[key].editableLayers && value[key].editableLayers.getLayers().length > 0) {
                newVal[key] = {
                    coordinates: value[key].editableLayers.getLayers()[0].getLatLngs().map(polygon => polygon.map(coord => [coord.lng, coord.lat])),
                    layers: []
                };
                value[key].editableLayers.getLayers().slice(1).forEach(it => {
                    const layerJson = {
                        properties: {
                            type: it.properties.type,
                            name: it.properties.name
                        },
                    };
                    if (it.properties.type === "room") {
                        layerJson.coordinates = it.getLatLngs().map(polygon => polygon.map(coord => [coord.lng, coord.lat]));
                    } else {
                        layerJson.properties.code = it.properties.code;
                        layerJson.coordinates = [[[it.getLatLng().lng, it.getLatLng().lat]]];
                    }
                    newVal[key].layers.push(layerJson)
                })
            }
        })
        return JSON.stringify(newVal, null, 2)
    },
    floorsDataFromJson(value) {
        const L = window.L;
        const result = {floors: {}};
        try {
            const data = JSON.parse(value);
            Object.keys(data).forEach(key => {
                const editableLayers = new L.FeatureGroup();
                const floor = L.polygon(data[key].coordinates[0].map(coor => [coor[1], coor[0]]), {
                    style: {
                        color: 'white'
                    }
                })
                floor.properties = {}
                editableLayers.addLayer(floor)
                data[key].layers.forEach(it => {
                    const properties = it.properties;
                    let layer;
                    if (!it.properties.type || it.properties.type === 'room') {
                        layer = L.polygon(it.coordinates[0].map(coor => [coor[1], coor[0]]), {
                            style: {
                                color: 'white'
                            }
                        })
                    } else {
                        const iconClass = (() => {
                            switch (properties.type) {
                                case 'door':
                                    return 'icon-opened-door-aperture'
                                case 'stairs':
                                    return 'icon-stairs'
                                case 'escalator':
                                    return 'icon-escalator'
                                case 'elevator':
                                    return 'icon-elevator'
                                default:
                                    return ''
                            }
                        })();
                        const coord = it.coordinates;
                        layer = new L.marker([coord[0][0][1], coord[0][0][0]], {
                            icon: mapComponents.icon({
                                color: properties.success ? "green" : "red",
                                iconClass: iconClass
                            }),
                            zIndexOffset: 1000,
                        });
                    }
                    layer.properties = properties;
                    editableLayers.addLayer(layer)
                });
                if (!result.floors[key]) {
                    result.floors[key] = {}
                }
                result.floors[key].editableLayers = editableLayers;
            });
            result.success = true;
            Object.keys(result.floors).forEach(floorKey => {
                const floor = result.floors[floorKey];
                const firstLayer = floor.editableLayers.getLayers()[0];
                firstLayer.properties.success = drawingRules.floorCheck(firstLayer.getLatLngs())
                floor.editableLayers.getLayers().slice(1).forEach(layer => {
                    layer.properties.success = drawingRules[layer.properties.type + "Check"](layer.getLatLngs ? layer.getLatLngs() : layer.getLatLng(), floor.editableLayers.getLayers());
                });
                const pointLayersTemp = floor.editableLayers.getLayers().slice(1).filter(layer => layer.properties.type !== "room");
                pointLayersTemp.forEach(layer => {
                    floor.editableLayers.removeLayer(layer);
                })
                pointLayersTemp.forEach(layer => {
                    const iconClass = (() => {
                        switch (layer.properties.type) {
                            case 'door':
                                return 'icon-opened-door-aperture'
                            case 'stairs':
                                return 'icon-stairs'
                            case 'escalator':
                                return 'icon-escalator'
                            case 'elevator':
                                return 'icon-elevator'
                            default:
                                return ''
                        }
                    })();
                    const coord = layer.getLatLng();
                    const newLayer = new L.marker([coord.lat, coord.lng], {
                        icon: mapComponents.icon({
                            color: layer.properties.success ? "green" : "red",
                            iconClass: iconClass
                        }),
                        zIndexOffset: 1000,
                    });
                    newLayer.properties = layer.properties;
                    floor.editableLayers.addLayer(newLayer);
                })
            })
        } catch (e) {
            result.success = false;
        }
        return result
    }
}

export default actions;