<template>
  <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
    <div v-for="(step,i) in steps" v-bind:key="step" :class="e1 > i-1 ? 'step completed' : 'step'">
      <h4 class="step-title">{{ step }}</h4>
      <div class="step-icon-wrap">
        <div class="step-icon"><i class="pe-7s-cart"></i></div>
      </div>
    </div>
  </div>
  <div class="pl-2 pr-2">
    <slot :name="parentSlots[e1]"/>
  </div>
  <v-divider/>
  <div class="d-flex justify-space-between">
    <v-btn
        color="purple"
        @click="e1--"
        :disabled="e1 === 0"
    >
      {{ $t('stepper.back') }}
    </v-btn>

    <v-btn
        color="purple"
        @click="next"
        :disabled="e1 === steps.length-1">
      {{ $t('stepper.next') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "StepperView",
  props: {
    steps: Array,
    stepConditions: Object,
    onChange: Function
  },
  setup(props, ctx) {
    const parentSlots = Object.keys(ctx.slots)

    return {parentSlots}
  },
  data() {
    return {
      e1: 0
    }
  },
  methods: {
    next() {
      if (!this.stepConditions || !(this.e1 in this.stepConditions) || Object.values(this.stepConditions)[this.e1] === true) {
        this.e1++;
      }
    }
  },
  watch: {
    e1(newVal, oldVal) {
      if (this.onChange) {
        this.onChange(newVal, oldVal)
      }
    }
  }
}
</script>

<style scoped>
.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: '';
  z-index: 1
}

.steps .step .step-icon-wrap::before {
  left: 0
}

.steps .step .step-icon-wrap::after {
  right: 0
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 81px;
  z-index: 5
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
  display: none
}

.steps .step:last-child .step-icon-wrap::after {
  display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #df6dec
}

.steps .step.completed .step-icon {
  border-color: #df6dec;
  background-color: #df6dec;
  color: #fff
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none
  }
}

.bg-faded, .bg-secondary {
  background-color: #f5f5f5 !important;
}
</style>