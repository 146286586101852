<template>
  <div>
    <v-dialog
        v-model="addWaypointDialog"
        class="w-100"
        style="max-width: 1000px"
    >
      <v-form v-model="addWaypointDialogValidate"
              @submit.prevent="addWaypoint">
        <v-card class="overflow-auto d-flex align-items-center text-center col-12 dialog-card">
          <v-card-title>{{ $t('database.waypoint.add_waypoint') }}</v-card-title>
          <i class="fas fa-window-close" style="font-size: x-large;
              right: 5px;
              top: 5px;
              position: absolute;
              cursor: pointer"
             @click="addWaypointDialog=false"/>
          <v-row class="col-12">
            <v-card-text class="col-12 col-lg-6 col-md-6 col-sm-12">
              <v-text-field
                  density="compact"
                  variant="solo"
                  :label="$t('database.waypoint.title')"
                  v-model="newWaypoint.title"
                  append-inner-icon="mdi-format-title"
                  single-line
                  :rules="[
                  v => !!v || 'Field is required'
                ]"
                  hide-details
              ></v-text-field>
              <v-text-field
                  density="compact"
                  variant="solo"
                  :label="$t('database.waypoint.description')"
                  v-model="newWaypoint.description"
                  append-inner-icon="mdi-note-text"
                  single-line
                  hide-details
                  class="mt-1"
              ></v-text-field>
              <v-text-field
                  density="compact"
                  variant="solo"
                  :label="$t('database.waypoint.load')"
                  v-model="newWaypoint.load"
                  append-inner-icon="mdi-warehouse"
                  single-line
                  hide-details
                  :rules="[
                    v => !!v || 'Field is required'
                  ]"
                  type="number"
                  class="mt-1"
              ></v-text-field>
              <v-text-field
                  density="compact"
                  variant="solo"
                  :label="$t('database.waypoint.coordinate_latitude')"
                  v-model="lat"
                  @input="changeLat($event.target.value)"
                  append-inner-icon="mdi-latitude"
                  single-line
                  hide-details
                  :rules="[
                    v => !!v || 'Field is required'
                  ]"
                  type="number"
                  class="mt-1"
              ></v-text-field>
              <v-text-field
                  density="compact"
                  variant="solo"
                  :label="$t('database.waypoint.coordinate_longitude')"
                  v-model="lon"
                  @input="changeLon($event.target.value)"
                  append-inner-icon="mdi-longitude"
                  single-line
                  hide-details
                  :rules="[
                    v => !!v || 'Field is required'
                  ]"
                  type="number"
                  class="mt-1"
              ></v-text-field>
              <v-text-field
                  density="compact"
                  variant="solo"
                  :label="$t('database.waypoint.address')"
                  v-model="newWaypoint.address"
                  append-inner-icon="mdi-map-marker"
                  single-line
                  hide-details
                  class="mt-1"
              ></v-text-field>
            </v-card-text>
            <div class="col-12 col-lg-6 col-md-6 col-sm-12">
              <selector-map :lat="lat" :lon="lon" v-on:onMarkerChanged="(data)=>onMarkerChange(data)"/>
            </div>
          </v-row>
          <v-card-actions>
            <v-btn type="submit" color="purple" block>{{ $t('database.save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="d-flex justify-content-end">
      <v-btn color="purple" class="m-2" @click="addWaypointDialog=true">+ {{ $t('database.add_new') }}</v-btn>
    </div>
    <v-table v-if="waypoints.length>0">
      <thead>
      <tr>
        <th class="text-left">
          {{ $t('database.waypoint.title') }}
        </th>
        <th class="text-left">
          {{ $t('database.waypoint.description') }}
        </th>
        <th class="text-left">
          {{ $t('database.waypoint.address') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="waypoint in waypoints.slice((page-1)*10,page*10)"
          :key="waypoint.id">
        <td>{{ waypoint.title }}</td>
        <td>{{ waypoint.description }}</td>
        <td>{{ waypoint.address }}</td>
      </tr>
      </tbody>
    </v-table>
    <v-row class="m-4 d-flex justify-content-center" v-else>
      <v-card-subtitle>{{ $t('database.waypoint.there_are_no_waypoints') }}</v-card-subtitle>
    </v-row>
    <v-pagination
        v-model="page"
        :length="waypoints.length/10+1"
        rounded="circle"
    ></v-pagination>
  </div>
</template>

<script>
import actions from "@/api/waypoint"
import SelectorMap from "@/components/SelectorMap";

export default {
  name: "WaypointContainer",
  components: {SelectorMap},
  data: () => ({
    page: 1,
    addWaypointDialog: false,
    addWaypointDialogValidate: false,
    lat: null,
    lon: null,
    newWaypoint: {
      user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
      title: null,
      description: null,
      coordinate: null,
      address: null,
      load: null
    },
    waypoints: []
  }),
  methods: {
    async addWaypoint() {
      if (this.addWaypointDialogValidate) {
        this.newWaypoint.coordinate = JSON.stringify([this.lat, this.lon]);
        await actions.save(this.newWaypoint);
        this.newWaypoint = {
          user: "/api/waypoint/" + JSON.parse(localStorage.getItem("user")).id,
          title: null,
          description: null,
          coordinate: null,
          address: null,
          load: null
        }
        this.addWaypointDialog = false;
        this.getWaypoints();
      }
    },
    async getWaypoints() {
      this.waypoints = await actions.findAllByUserId(JSON.parse(localStorage.getItem("user")).id);
    },
    onMarkerChange(data){
      var coordinates = data.coordinates;
      this.newWaypoint.address = data.address;
      this.lat = coordinates.lat;
      this.lon = coordinates.lng;
    },
    changeLon(val){
      this.lon=val;
      SelectorMap.watch.lon1(this.lon);
    },
    changeLat(val){
      this.lat=val;
      SelectorMap.watch.lat1(this.lat);
    }
  },
  mounted() {
    this.getWaypoints();
  }
}
</script>

<style scoped>
.dialog-card{
  flex-direction: column; max-height: 80vh;
}
</style>