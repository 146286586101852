export default {
  "header": {
    "menu": {
      "homepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anasayfa"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetler"])},
      "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSS"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım Alanları"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])}
    },
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GİRİŞ YAP"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYIT OL"])},
    "user_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KULLANICI PANELİ"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÇIKIŞ"])},
    "whatsapp_support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WhatsApp Destek"])}
  },
  "home": {
    "intro": {
      "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşinize Odaklanın"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita ve rotalama işlerini bize bırakın. Siz işinizi büyütmeye odaklanın."])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişime Geç"])}
    },
    "companies": {
      "references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizi tercih eden firmalar"])}
    },
    "services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetlerimiz"])},
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha fazla bilgi"])},
      "description": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hizmetlerimiz; geliştiricilerden, son kullanıcıya kadar herkes için çözüm sunuyor"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harita veya rotalama alanlarında ihtiyaçları olan firmalar sitemiz üzerinden hizmetlerimize erişebilir. Yazılım geliştiriciler, API desteği ile hizmetlerimizi kolaylıkla kendi sistemlerine entegre edebilirler."])}
      },
      "services": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotaya Bağlı Görev Gruplama"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçlarınız ile uğranması gereken noktaları otomatik olarak gruplar ve uygun arçlarla eşleştirir. Böylelikle yakıt ve süreden tasarruf sağlanır. Yolcu taşıma, kargo dağıtma ve toplama, mal tedariği gibi birçok alanda kullanılabilir."])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geliştiricilere Uygun"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api desteği ile hizmetlerimizi kendi mobil, masaüstü veya web yazılımlarınızda kolaylıkla kullanabilirsiniz."])}
        },
        "3": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düşük Fiyatlar"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelişmiş özelliklere düşük fiyatlarla erişebileceksiniz. Ön ödemeli kredi sistemi ile hesabınıza yüklediğiniz kredileri istediğiniz hizmet için kullanabilirsiniz. Kotayı aşmazsınız ve yüksek faturalarla karşılaşmazsınız."])}
        }
      }
    }
  },
  "notfound": {
    "goback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GERİ DÖN"])}
  },
  "mail": {
    "mail_verification": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta adresinizi doğrulamak için aşağıdaki 6 haneli kodu doğrulama alanına giriniz. Bu işlemi siz gerçekleştirmediyseniz, herhangi bir işlem yapmanıza gerek yoktur."])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SayberDATA E-posta Doğrulama"])}
    }
  },
  "sms": {
    "sms_verification": {
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SayberDATA hesap doğrulama kodunuz: "])}
    }
  },
  "registration": {
    "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BİREYSEL"])},
    "corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KURUMSAL"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Adı"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "password_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre(Tekrar)"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon Numarası"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta Adresi"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYIT OL"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEMİZLE"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt başarıyla tamamlandı. Giriş yapabilirsiniz."])},
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu"])},
    "username_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı adı zaten kullanılıyor"])},
    "email_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta zaten kullanılıyor"])}
  },
  "login": {
    "user_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KULLANICI GİRİŞİ"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GİRİŞ"])},
    "username_or_mail_or_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı Adı/E-posta/Telefon"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarıyla giriş yapıldı"])},
    "incorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiğiniz bilgileri kontrol ediniz"])}
  },
  "panel": {
    "menu": {
      "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özet"])},
      "my_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabım"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkış"])},
      "tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçlar"])},
      "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanı"])},
      "grouping_routes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rota Gruplama"])},
      "history_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçmiş İşlemler"])},
      "indoor_design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bina İçi Tasarım"])}
    },
    "account_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap Bakiyesi"])}
  },
  "profile": {
    "personal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bireysel Hesap"])},
    "corporate_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurumsal Hesap"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyad"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])}
  },
  "grouping_routes": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAPAT"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "route_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rota Detayları"])},
    "use_all_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm araçlar kullanılsın mı?"])},
    "all_vehicles_will_be_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm araçlar kullanılacak"])},
    "the_required_number_of_vehicles_will_be_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli sayıda araç kullanılacak"])},
    "select_the_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçların çıkış konumunu seçiniz(Depo, merkez vb.)"])},
    "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçlar"])},
    "waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara Noktalar"])},
    "grouping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruplama"])},
    "editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenleme"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıktı"])},
    "select_transportation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taşıma türünü seçiniz"])},
    "transportation_types": {
      "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adete Göre(Yolcu vb.)"])},
      "desi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desiye Göre(Kargo vb.)"])}
    },
    "add_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARAÇ EKLE"])},
    "vehicle_dialog": {
      "new_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YENİ ARAÇ"])},
      "saved_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYITLI ARAÇ"])},
      "plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaka"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
      "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasite"])},
      "save_to_database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanına kaydet"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYDET"])},
      "select_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçları seçiniz"])},
      "there_are_no_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı araç bulunmuyor"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EKLE"])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
    "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasite"])},
    "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adet"])},
    "desi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desi"])},
    "waypoint_dialog": {
      "new_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YENİ ARA NOKTA"])},
      "saved_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYITLI ARA NOKTA"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "add_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARA NOKTA EKLE"])},
      "coordinate_latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinat(Enlem)"])},
      "coordinate_longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinat(Boylam)"])},
      "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yük Miktarı(Adet veya Desi)"])},
      "save_to_database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veritabanına kaydet"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYDET"])},
      "select_waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara noktaları seçiniz"])},
      "there_are_no_waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı ara nokta bulunmuyor"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EKLE"])}
    },
    "waypoints_not_less_than_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara nokta sayısı, araç sayısından az olmamalı"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAŞI"])},
    "select_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara Nokta Seçiniz"])},
    "select_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araç Seçiniz"])},
    "substitution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YER DEĞİŞTİR"])},
    "substitute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEĞİŞTİR"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantıyı kopyala"])},
    "the_capacity_of_one_vehicles_is_insufficient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçlardan birinin kapasitesi bu değiştirme için yetersiz"])},
    "insufficient_capacity_of_the_selected_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seçili aracın kapasitesi yetersiz"])},
    "history_log": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Tipi"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durum"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
      "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı"])},
      "unsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarısız"])},
      "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredi"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaylar"])},
      "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç"])}
    }
  },
  "history_logs": {
    "there_are_no_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı veri bulunmuyor"])}
  },
  "database": {
    "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araçlar"])},
    "waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara Noktalar"])},
    "add_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YENİ EKLE"])},
    "vehicle": {
      "plate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaka"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
      "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapasite"])},
      "add_vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARAÇ EKLE"])},
      "transportation_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taşıma Tipi"])},
      "transportation_types": {
        "pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adete Göre(Yolcu vb.)"])},
        "desi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desiye Göre(Kargo vb.)"])}
      },
      "there_are_no_vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı araç bulunmuyor"])}
    },
    "waypoint": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlık"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Açıklama"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "add_waypoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARA NOKTA EKLE"])},
      "coordinate_latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinat(Enlem)"])},
      "coordinate_longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinat(Boylam)"])},
      "there_are_no_waypoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıtlı ara nokta bulunmuyor"])},
      "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yük Miktarı(Adet veya Desi)"])}
    },
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYDET"])}
  },
  "rules": {
    "cannot_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan boş olamaz"])},
    "min_two_char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu alan minimum 2 karakter içermelidir."])},
    "cannot_include_special_char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özel karakter içermemelidir"])},
    "mail_is_not_compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail adresi uyumlu değil"])},
    "invalid_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçersiz telefon numarası"])},
    "passwords_does_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifreler eşleşmiyor"])}
  },
  "stepper": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GERİ"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İLERİ"])}
  },
  "selector_map": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])},
    "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonuç bulunamadı"])},
    "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama Sonuçları"])}
  },
  "vehicle": {
    "something_went_wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hata oluştu"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araç başarıyla kayıt edildi."])},
    "plate_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaka zaten kayıtlı"])}
  },
  "verification": {
    "verify_at_least_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen aşağıdaki bilgilerinizden en az bir tanesini doğrulayanız:"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrulandı"])},
    "remaining_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalan Süre"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOĞRULA"])},
    "verify_l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğrula"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saniye"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "verify_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postayı Doğrula"])},
    "verify_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonu Doğrula"])},
    "verify_phone_reward_1000_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon numaranızı doğrulayarak 1000 kredi kazanabilirsiniz."])},
    "user_panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KULLANICI PANELİ"])}
  }
}