import makeCrud from '../crud';
import httpReq from '../request';

var USER = 'user';
var crud = new makeCrud(USER)
const index = {
    save: (data) => {
        return crud.save(data)
    },
    update: (data) => {
        return crud.update(data)
    },
    delete: (id) => {
        return crud.delete(id)
    },
    findById: (id) => {
        return crud.findById(id)
    },
    register: (data) => {
        return httpReq.post('/user/registration/', data, {'headers': {'Content-Type': 'application/json'}})
    },
    login: (username, password) => {
        return httpReq.get('/api/user/search/findByUsernameOrEmailOrPhoneAndPassword?username=' + username + "&password=" + password)
    },
    verificationMail: (data, subject, code) => {
        let user = JSON.parse(localStorage.getItem("user"));
        return httpReq.post('/user/verification/mail/' + subject + "/" + code + "/" + user.username + "/" + user.accessKey, data, {'headers': {'Content-Type': 'application/json'}})
    },
    verificationSMS: (data) => {
        let user = JSON.parse(localStorage.getItem("user"));
        return httpReq.post('/user/verification/sms/' + user.username + "/" + user.accessKey, data, {'headers': {'Content-Type': 'application/json'}})
    }
}

export default index;