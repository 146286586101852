const baseUrl = "https://nominatim.openstreetmap.org/";
import httpReq from '../request';

const nominatim = {
    search: async (query) => {
        return (await httpReq.get(baseUrl + "search?format=json&q=" + query)).data
    },
    reverse: async (lat,lon) => {
        return (await httpReq.get(baseUrl + "reverse?lat=" + lat + "&lon=" + lon + "&format=json")).data
    }
}

export default nominatim;