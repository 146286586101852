<template>
  <div ref="designArea">
    <div v-if="windowWidth > 585">
      <div :class="'border ' + (layersPanel ? 'layers-open' : 'layers-close')"
           style="height: calc(100% - 70px); width: calc(100% - 20px); position: absolute;">
        <v-dialog
            v-model="layerEditDialog"
            style="max-width: 500px"
        >
          <v-form ref="layerEditForm" v-model="layerEditDialogValid">
            <v-card>
              <div class="position-absolute" style="right: 10px; top: 10px; z-index: 999;">
                <i role="button" @click="layerEditDialog=false" class="mdi mdi-close"/></div>
              <v-card-text>
                <v-text-field label="Başlık" v-model="layerEditDialogModel.name"
                              :rules="[v => !!v || 'Field is required', v => !!v && (this.isUniqueName(v) || v === this.layerEditDialogModel.name) || 'Name must be unique']"
                              variant="underlined"></v-text-field>
                <v-text-field label="Açıklama" v-model="layerEditDialogModel.description"
                              variant="underlined"></v-text-field>
                <div class="form-group"
                     v-if="layerEditDialogModelTemp.properties.type && layerEditDialogModelTemp.properties.type !== 'room'">
                  <v-text-field label="Kod" v-model="layerEditDialogModel.code"
                                variant="underlined">
                  </v-text-field>
                  <small class="text-muted">(Aynı koda sahip, farklı kattaki bileşenler birbirine bağlanır.)</small>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" block @click="submitEditLayer">Kaydet</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <selector-map id="left" ref="mapRef" style="width: 70%; float:left" :lat="lat" :lon="lon"
                      v-on:onMarkerChanged="(data)=>onMarkerChange(data)">
          <template #inner>
              <div class="position-absolute bg-white d-flex flex-column"
                   style="right:10px; top: 200%; pointer-events: auto;">
                <span class="p-1 bg-dark text-white" style="font-size: 10px">EKLE</span>
                <v-divider style="margin: 0"/>
                <a role="button" @click="addPlan"
                   :style="'padding: 5px; font-size: 18px; ' + (roomDrawer ? 'color:purple':'')"><i
                    class="icon-plans"/></a>
                <v-divider style="margin: 0"/>
                <a role="button" @click="addDoor"
                   :style="'padding: 5px; font-size: 18px; ' + (drawing && drawing.properties.type === 'door' ? 'color:purple':'')"><i
                    class="icon-opened-door-aperture"/></a>
                <v-divider style="margin: 0"/>
                <a role="button" @click="addStairs"
                   :style="'padding: 5px; font-size: 18px; ' + (drawing && drawing.properties.type === 'stairs' ? 'color:purple':'')"><i
                    class="icon-stairs"/></a>
                <v-divider style="margin: 0"/>
                <a role="button" @click="addEscalator"
                   :style="'padding: 5px; font-size: 18px; ' + (drawing && drawing.properties.type === 'escalator' ? 'color:purple':'')"><i
                    class="icon-escalator"/></a>
                <v-divider style="margin: 0"/>
                <a role="button" @click="addElevator"
                   :style="'padding: 5px; font-size: 18px; ' + (drawing && drawing.properties.type === 'elevator' ? 'color:purple':'')"><i
                    class="icon-elevator"/></a>
                <div v-if="floors[selectedFloor] && floors[selectedFloor].editableLayers.getLayers().length===0"
                     class="position-absolute w-100 h-100"
                     style="background: rgba(26,30,33,0.49)"></div>
                <v-divider style="margin: 0"/>
                <v-btn @click="cancelAllDrawings" class="text-danger" v-if="drawing != null || roomDrawer != null"
                       style="height:auto;padding: 5px; position: absolute; right: 40px; font-weight: bold">
                  <i class="mdi mdi-close"/>İPTAL
                </v-btn>
              </div>
          </template>
        </selector-map>
        <div class="position-absolute bg-dark text-white"
             style="width: calc(70% - 10px); bottom:0; padding-left: 10px; padding-right: 10px; pointer-events: auto; z-index: 9999">
          <a role="button" @click="addFloor">+ KAT EKLE</a>
          <v-breadcrumbs-divider>|</v-breadcrumbs-divider>
          <a :role="selectedFloor>0 ? 'button' : 'text'"
             :class="selectedFloor>0 ? 'text-white' : 'text-grey-darken-1'"
             @click="changeFloor(selectedFloor-1)"><i class="fas fa-arrow-down"/></a>
          <span class="pl-1 pr-1">KAT {{ selectedFloor + 1 }}</span>
          <a :role="selectedFloor<(Object.keys(floors).length-1) ? 'button' : 'text'"
             :class="selectedFloor<(Object.keys(floors).length-1) ? 'text-white' : 'text-grey-darken-1'"
             @click="changeFloor(selectedFloor+1)"><i
              class="fas fa-arrow-up"/></a>
          <v-breadcrumbs-divider>|</v-breadcrumbs-divider>
          <a :role="selectedFloor !== 0 && this.selectedFloor === (Object.keys(floors).length-1) ? 'button' : 'text'"
             :class="selectedFloor !== 0 && this.selectedFloor === (Object.keys(floors).length-1) ? 'text-white' : 'text-grey-darken-1'"
             style="text-decoration-line: none" @click="removeFloor">KATI SİL</a>
        </div>
        <div id="right" class="bg-light text-grey-darken-3" style="float: right; width: 30%; height: 100%">
          <div id="button" class="position-relative" style="top: calc(50% - 16px); width: 0">
            <button class="card" style="width: auto; height: auto" @click="layerPanelChange"><i
                :class="layersPanel ? 'fas fa-caret-right' : 'fas fa-caret-left'" style="font-size: 32px"/></button>
          </div>
          <div style="margin-top: -34px; height: 100%;" v-if="layersPanel">
            <div class="d-flex flex-row justify-space-between">
              <v-alert-title class="bg-dark text-white p-1" style="width: calc(100% - 35px)">
                {{ codeOpened ? "PCODE" : "KATMANLAR" }}
              </v-alert-title>
              <i role="button" @click="codeOpened = !codeOpened"
                 class="fas fa-code bg-dark text-white d-flex align-items-center justify-content-center"
                 style="width:34px; z-index: 9999"/>
            </div>
            <textarea v-if="codeOpened"
                      style="resize: none;height: calc(100% - 35px); width: calc(100% - 14px); margin-left: 15px; font-size: small;"
                      v-model="json"
                      :class="(jsonSuccess ? 'text-body':'text-danger')" @change="fromJson()"/>
            <div v-else>
              <div class="w-100 p-1 d-flex justify-space-between align-items-center">
                <i @click="changeFloor(selectedFloor-1)" :role="selectedFloor>0 ? 'button' : 'text'"
                   :class="'fas fa-arrow-left ' +(selectedFloor>0 ? 'text-black' : 'text-grey-darken-1')"/>
                <span> KAT {{ selectedFloor + 1 }}</span>
                <i @click="changeFloor(selectedFloor+1)"
                   :role="selectedFloor<(Object.keys(floors).length-1) ? 'button' : 'text'"
                   :class="'fas fa-arrow-right ' +(selectedFloor<(Object.keys(floors).length-1) ? 'text-black' : 'text-grey-darken-1')"/>
              </div>
              <v-divider class="m-0"/>
              <div v-if="floors[selectedFloor] && floors[selectedFloor].editableLayers.getLayers().length > 0">
                <div class="d-flex justify-space-between mr-2 align-items-center">
                  <v-card-subtitle style="width: calc(100% - 62px)">zemin1</v-card-subtitle>
                  <div>
                    <i role="button" @click="showLayer(floors[selectedFloor].editableLayers.getLayers()[0])"
                       class="fas fa-location-arrow ml-2"/>
                    <i role="button" @click="deleteLayer()" class="fas fa-trash ml-2"/>
                  </div>
                </div>
                <div
                    v-for="layer in floors[selectedFloor].editableLayers.getLayers().slice(1).filter(it=>!it.properties.parent)"
                    :key="layer.properties.name">
                  <div class="mr-2 ml-4 p-0 d-flex">
                    <i class="mdi mdi-subdirectory-arrow-right"/>
                    <div style="width: 100%">
                      <div class="d-flex justify-space-between align-items-center">
                      <span class="p-0 v-card-subtitle" style="width: calc(100% - 62px)">{{
                          layer.properties.name
                        }}</span>
                        <div class="text-grey-darken-3">
                          <i role="button" @click="settingsDialog(layer)" class="fas fa-gear"/>
                          <i role="button" @click="showLayer(layer)" class="fas fa-location-arrow ml-2"/>
                          <i role="button" @click="deleteLayer(layer)" class="fas fa-trash ml-2"/>
                        </div>
                      </div>
                      <div v-for="sub in getSubs(layer.properties.name)" :key="sub" class="d-flex">
                        <i class="mdi mdi-subdirectory-arrow-right"/>
                        <div style="width: 100%"
                             class="d-flex justify-space-between align-items-center">
                        <span class="p-0 v-card-subtitle" style="width: calc(100% - 62px)">{{
                            sub.properties.name
                          }}</span>
                          <div class="text-grey-darken-3">
                            <i role="button" @click="settingsDialog(sub)" class="fas fa-gear"/>
                            <i role="button" @click="showLayer(sub)" class="fas fa-location-arrow ml-2"/>
                            <i role="button" @click="deleteLayer(sub)" class="fas fa-trash ml-2"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <v-card-subtitle>Eklenmiş katman bulunmuyor</v-card-subtitle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <v-alert>
        <v-alert-title>Bu özelliği kullanmak için ekran boyutunuz yeterli değildir.</v-alert-title>
      </v-alert>
    </div>
  </div>
</template>

<script>
import SelectorMap from "@/components/SelectorMap.vue";
import findIntersections from "sweepline-intersections"
import drawingRules from "@/components/indoorLib/rules"
import mapComponents from "@/components/mapComponents";
import pCode from "@/constaints/pCode";

const L = window.L;

export default {
  name: "IndoorDesignMap",
  components: {SelectorMap},
  data: () => ({
    windowWidth: 1000,
    json: null,
    codeOpened: false,
    jsonSuccess: true,
    layerEditDialog: false,
    layerEditDialogValid: false,
    layerEditDialogModel: null,
    layerEditDialogModelTemp: null,
    lat: 0,
    lon: 0,
    selectedFloor: 0,
    floors: {
      0: {
        editableLayers: new L.FeatureGroup()
      }
    },
    addLayerButton: null,
    removeLayerButton: null,
    drawControl: null,
    floorDrawer: null,
    roomDrawer: null,
    drawing: null,
    layersPanel: true,
  }),
  methods: {
    cancelAllDrawings() {
      if (this.drawing) {
        this.drawing.remove();
        this.drawing._map = this.$refs.mapRef.$refs.mapRef.map;
        this.drawing = null;
        this.$refs.mapRef.$refs.mapRef.map.off('click');
      }
      if (this.roomDrawer) {
        this.roomDrawer.disable();
        this.roomDrawer = null;
      }
    },
    onMarkerChange() {
      if (this.$refs.mapRef.marker)
        this.$refs.mapRef.$refs.mapRef.map.removeLayer(this.$refs.mapRef.marker)
    },
    changeFloor(value) {
      if (value > -1 && value < Object.keys(this.floors).length) {
        this.selectedFloor = value
        this.configs()
      }
    },
    addFloor() {
      const index = Object.keys(this.floors).length;
      this.floors[index] = {
        editableLayers: new L.FeatureGroup()
      }
      const floorBase = this.floors[index - 1].editableLayers.getLayers()[0]
      if (floorBase) {
        var copyPoly = L.polygon(floorBase.getLatLngs(), floorBase.options);
        copyPoly.options.color = '#df6dec'
        copyPoly.properties = {
          success: floorBase.properties.success,
          parent: null
        }
        this.floors[index].editableLayers.addLayer(copyPoly)
      }
      this.changeFloor(Object.keys(this.floors).length - 1)
    },
    removeFloor() {
      if (this.selectedFloor === (Object.keys(this.floors).length - 1) && this.selectedFloor !== 0) {
        this.$refs.mapRef.$refs.mapRef.map.removeLayer(this.floors[this.selectedFloor].editableLayers)
        this.floors.splice(this.selectedFloor, 1)
        this.changeFloor(this.selectedFloor - 1)
      }
    },
    getOptions() {
      return {
        position: 'topleft',
        draw: {
          polygon1: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: '#e10000', // Color the shape will turn when intersects
              message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
            },
            shapeOptions: {
              color: '#bada55'
            }
          },
          polygon2: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: '#e10000', // Color the shape will turn when intersects
              message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
            },
            shapeOptions: {
              color: '#bada55'
            }
          },
          polygon: false,
          circle: false, // Turns off this drawing tool
          rectangle: false,
          polyline: false,
          marker: false,
          circlemarker: false
        },
        edit: {
          featureGroup: this.floors[this.selectedFloor].editableLayers, //REQUIRED!!
          remove: false
        }
      };
    },
    configs() {
      const that = this;
      const json = that.toJson(that.floors)
      that.jsonSuccess = true;
      if (that.json !== json) {
        that.json = json;
      }
      that.$refs.mapRef.$refs.mapRef.map.eachLayer(function (layer) {
        if (!(layer instanceof L.TileLayer)) {
          that.$refs.mapRef.$refs.mapRef.map.removeLayer(layer);
        }
      });
      that.$refs.mapRef.$refs.mapRef.map.on('mousemove', that.mousemove);
      Object.values(that.floors).forEach((val) => {
        that.$refs.mapRef.$refs.mapRef.map.removeLayer(val.editableLayers);
      })
      if (Object.keys(that.floors).length > 1 && that.selectedFloor !== 0) {
        for (const i of Array(that.selectedFloor).keys()) {
          var floor1 = that.floors[i].editableLayers;
          floor1.getLayers().forEach((layer) => {
            if (layer.getIcon) {
              const coord = layer.getLatLng();
              const iconClass = (() => {
                switch (layer.properties.type) {
                  case 'door':
                    return 'icon-opened-door-aperture'
                  case 'stairs':
                    return 'icon-stairs'
                  case 'escalator':
                    return 'icon-escalator'
                  case 'elevator':
                    return 'icon-elevator'
                  default:
                    return ''
                }
              })();
              const newLayer = new L.marker([coord.lat, coord.lng], {
                icon: mapComponents.icon({
                  color: "grey",
                  iconClass: iconClass
                }),
                zIndexOffset: 1000,
              });
              newLayer.properties = layer.properties;
              floor1.removeLayer(layer);
              floor1.addLayer(newLayer);
            }
            layer.options.color = '#a3a096'
          })
          that.$refs.mapRef.$refs.mapRef.map.addLayer(floor1);
        }
      }

      var floorLast = that.floors[that.selectedFloor].editableLayers;
      floorLast.getLayers().forEach((layer) => {
        if (layer.getIcon) {
          const coord = layer.getLatLng();
          const iconClass = (() => {
            switch (layer.properties.type) {
              case 'door':
                return 'icon-opened-door-aperture'
              case 'stairs':
                return 'icon-stairs'
              case 'escalator':
                return 'icon-escalator'
              case 'elevator':
                return 'icon-elevator'
              default:
                return ''
            }
          })();
          const newLayer = new L.marker([coord.lat, coord.lng], {
            icon: mapComponents.icon({
              color: layer.properties.success ? "green" : "red",
              iconClass: iconClass
            }),
            zIndexOffset: 1000,
          });
          newLayer.properties = layer.properties;
          floorLast.removeLayer(layer);
          floorLast.addLayer(newLayer);
        } else if (!layer.properties.success) {
          layer.options.color = '#e10000'
        } else if (layer.properties.type && layer.properties.type === "room") {
          layer.options.color = '#d9b500'
        } else {
          layer.options.color = '#df6dec'
        }
      })
      that.$refs.mapRef.$refs.mapRef.map.addLayer(floorLast);

      if (!that.addLayerButton) {
        that.addLayerButton = L.easyButton('<i class="fas fa-draw-polygon" title="Zemin Ekle"/>', function (btn, map) {
          if (that.floorDrawer) {
            that.floorDrawer.disable();
            that.floorDrawer = null;
          }
          that.floorDrawer = new L.Draw.Polygon(map, that.getOptions().polygon1);
          that.floorDrawer.enable();
        });
      }
      if (that.drawControl) {
        that.$refs.mapRef.$refs.mapRef.map.removeControl(that.drawControl)
      }
      that.drawControl = new L.Control.Draw(that.getOptions());
      if (!that.removeLayerButton) {
        that.removeLayerButton = L.easyButton('<i class="fas fa-trash"></i>', function () {
          if (that.drawing) {
            that.drawing = null;
            that.$refs.mapRef.$refs.mapRef.map.off('click');
          }
          if (that.roomDrawer) {
            that.roomDrawer.disable();
            that.roomDrawer = null;
          }
          that.floors[that.selectedFloor].editableLayers.clearLayers();
          setTimeout(function () {
            that.configs();
          }.bind(that), 100)
        });
      }

      that.$refs.mapRef.$refs.mapRef.map.removeControl(that.addLayerButton)
      that.$refs.mapRef.$refs.mapRef.map.removeControl(that.drawControl)
      that.$refs.mapRef.$refs.mapRef.map.removeControl(that.removeLayerButton)

      if (that.floors[that.selectedFloor].editableLayers.getLayers().length === 0) {
        that.addLayerButton.addTo(that.$refs.mapRef.$refs.mapRef.map);
      } else {
        that.$refs.mapRef.$refs.mapRef.map.removeControl(that.addLayerButton)
        that.removeLayerButton.addTo(that.$refs.mapRef.$refs.mapRef.map);
        that.$refs.mapRef.$refs.mapRef.map.addControl(that.drawControl);
      }
      that.$refs.mapRef.$refs.mapRef.map.on(L.Draw.Event.CREATED, function (e) {
        const layer = e.layer;
        if (!layer.properties) {
          layer.properties = {}
        }
        if (!layer.properties.isAdded) {
          layer.properties.createdTime = new Date();
          layer.properties.isAdded = true;
          if (layer.properties.type && layer.properties.type !== 'room') {
            const icon = (() => {
              switch (layer.properties.type) {
                case 'door':
                  return 'icon-opened-door-aperture'
                case 'stairs':
                  return 'icon-stairs'
                case 'escalator':
                  return 'icon-escalator'
                case 'elevator':
                  return 'icon-elevator'
                default:
                  return ''
              }
            })();
            const successIcon = mapComponents.icon({color: "green", iconClass: icon});
            const errorIcon = mapComponents.icon({color: "red", iconClass: icon});
            const result = drawingRules[layer.properties.type + 'Check'](layer.getLatLng(), that.floors[that.selectedFloor].editableLayers.getLayers())
            if (result.success) {
              layer.setIcon(successIcon);
              layer.properties.success = true;
            } else {
              layer.setIcon(errorIcon);
              layer.properties.success = false;
            }
          } else {
            const featureCollection = {
              "type": "FeatureCollection", "features": [
                layer.toGeoJSON()
              ]
            }
            layer.properties.selfIntersections = findIntersections(featureCollection, false)
            if (that.floors[that.selectedFloor].editableLayers.getLayers().length === 0) {
              that.floorDrawer.disable();
              that.floorDrawer = null;
              layer.properties.parent = null;
              that.floors[that.selectedFloor].editableLayers.addLayer(layer);
              layer.properties.success = !(layer.properties.selfIntersections && layer.properties.selfIntersections.length > 0)
              setTimeout(function () {
                that.configs();
              }.bind(that), 100)
            } else {
              that.roomDrawer.disable();
              that.roomDrawer = null;
              layer.properties.isRoom = true;
              layer.properties.type = "room"
              layer.properties.name = that.getUniqueName(layer.properties.type)
              layer.bindTooltip(layer.properties.name, {
                noHide: true,
                direction: 'auto'
              });
              const featureCollection = {
                "type": "FeatureCollection", "features": [
                  that.floors[that.selectedFloor].editableLayers.getLayers()[0].toGeoJSON(),
                  layer.toGeoJSON()
                ]
              }
              layer.properties.floorIntersections = findIntersections(featureCollection, true)
              layer.properties.isInFloor = true;
              layer.getLatLngs()[0].forEach(latLng => {
                const polygonL = that.floors[that.selectedFloor].editableLayers.getLayers()[0].getLatLngs()[0];
                const polygon = polygonL.map(it => [it.lat, it.lng])
                var isIn = that.rayCasting([latLng.lat, latLng.lng], polygon)
                if (!isIn) {
                  layer.properties.isInFloor = false
                }
              })
              const layerList = that.floors[that.selectedFloor].editableLayers.getLayers().filter(it => it.options && it.properties.isRoom);
              const parents = []
              if (layerList.length > 1) {
                layerList.slice(1).forEach(it => {
                  const featureCollection2 = {
                    "type": "FeatureCollection", "features": [
                      it.toGeoJSON(),
                      layer.toGeoJSON()
                    ]
                  }
                  const intersections = findIntersections(featureCollection2, true);
                  const inter = intersections && intersections.length > 0
                  if (inter) {
                    layer.roomsIntersections = true;
                  }
                  let isInRoom = true;
                  layer.getLatLngs()[0].forEach(latLng => {
                    const polygonL = it.getLatLngs()[0];
                    const polygon = polygonL.map(it => [it.lat, it.lng])
                    var isIn = that.rayCasting([latLng.lat, latLng.lng], polygon)
                    if (!isIn) {
                      isInRoom = false
                    }
                  });
                  if (isInRoom) {
                    parents.push(it);
                  }
                })
              }
              let minArea;
              parents.forEach(parent => {
                const area = L.GeometryUtil.geodesicArea(parent.getLatLngs()[0]);
                if (!layer.properties.parent || area < minArea) {
                  minArea = area;
                  layer.properties.parent = parent
                }
              })
              layer.properties.success = !((layer.properties.selfIntersections && layer.properties.selfIntersections.length > 0) ||
                      (layer.properties.isRoom && (!layer.properties.isInFloor && !layer.properties.parent) && (!layer.properties.floorIntersections || layer.properties.floorIntersections.length === 0)))
                  && !layer.roomsIntersections;
              that.floors[that.selectedFloor].editableLayers.addLayer(layer);
              setTimeout(function () {
                that.configs();
              }.bind(that), 100)
            }
          }
        }
      });
      that.$refs.mapRef.$refs.mapRef.map.on(L.Draw.Event.EDITED, function (e) {
        var layers = e.layers.getLayers();
        layers.forEach(layer => {
          if (layer.properties.type && layer.properties.type !== 'room') {
            const icon = (() => {
              switch (layer.properties.type) {
                case 'door':
                  return 'icon-opened-door-aperture'
                case 'stairs':
                  return 'icon-stairs'
                case 'escalator':
                  return 'icon-escalator'
                case 'elevator':
                  return 'icon-elevator'
                default:
                  return ''
              }
            })();
            const successIcon = mapComponents.icon({color: "green", iconClass: icon});
            const errorIcon = mapComponents.icon({color: "red", iconClass: icon});
            const result = drawingRules[layer.properties.type + 'Check'](layer.getLatLng(), that.floors[that.selectedFloor].editableLayers.getLayers())
            if (result.success) {
              layer.setIcon(successIcon);
              layer.properties.success = true;
            } else {
              layer.setIcon(errorIcon);
              layer.properties.success = false;
            }
          } else {
            const featureCollection = {
              "type": "FeatureCollection", "features": [
                layer.toGeoJSON()
              ]
            }
            layer.properties.selfIntersections = findIntersections(featureCollection, false)
            const featureCollection2 = {
              "type": "FeatureCollection", "features": [
                that.floors[that.selectedFloor].editableLayers.getLayers()[0].toGeoJSON(),
                layer.toGeoJSON()
              ]
            }
            layer.properties.floorIntersections = findIntersections(featureCollection2, true)
            layer.properties.isInFloor = true;
            layer.getLatLngs()[0].forEach(latLng => {
              const polygonL = that.floors[that.selectedFloor].editableLayers.getLayers()[0].getLatLngs()[0];
              const polygon = polygonL.map(it => [it.lat, it.lng])
              var isIn = that.rayCasting([latLng.lat, latLng.lng], polygon)
              if (!isIn) {
                layer.properties.isInFloor = false
              }
            })
            if (layer.properties.isRoom) {
              layer.roomsIntersections = false
              const layerList = that.floors[that.selectedFloor].editableLayers.getLayers();
              const parents = []
              if (layerList.length > 1) {
                layerList.slice(1).filter(it => it.properties && it.properties.isRoom).forEach(it => {
                  if (it.properties.createdTime !== layer.properties.createdTime) {
                    const featureCollection2 = {
                      "type": "FeatureCollection", "features": [
                        it.toGeoJSON(),
                        layer.toGeoJSON()
                      ]
                    }
                    const intersections = findIntersections(featureCollection2, true);
                    const inter = intersections && intersections.length > 0
                    if (inter) {
                      layer.roomsIntersections = true;
                    }
                    let isInRoom = true;
                    layer.getLatLngs()[0].forEach(latLng => {
                      const polygonL = it.getLatLngs()[0];
                      const polygon = polygonL.map(it => [it.lat, it.lng])
                      var isIn = that.rayCasting([latLng.lat, latLng.lng], polygon)
                      if (!isIn) {
                        isInRoom = false
                      }
                    });
                    if (isInRoom) {
                      parents.push(it);
                    }
                  }
                })
              }
              layer.properties.parent = null;
              let minArea;
              parents.forEach(parent => {
                const area = L.GeometryUtil.geodesicArea(parent.getLatLngs()[0]);
                if (!layer.properties.parent || area < minArea) {
                  minArea = area;
                  layer.properties.parent = parent
                }
              })
            }
            layer.properties.success = !((layer.properties.selfIntersections && layer.properties.selfIntersections.length > 0) ||
                    (layer.properties.isRoom && (!layer.properties.isInFloor && !layer.properties.parent) && (!layer.properties.floorIntersections || layer.properties.floorIntersections.length === 0)))
                && !layer.roomsIntersections
          }
        })
        setTimeout(function () {
          that.configs();
        }.bind(that), 100)
      });
    },
    addPlan() {
      if (this.drawing) {
        this.drawing.remove();
        this.drawing._map = this.$refs.mapRef.$refs.mapRef.map;
        this.drawing = null;
        this.$refs.mapRef.$refs.mapRef.map.off('click');
      }
      if (this.roomDrawer) {
        this.roomDrawer.disable();
        this.roomDrawer = null;
      } else {
        this.roomDrawer = new L.Draw.Polygon(this.$refs.mapRef.$refs.mapRef.map, this.getOptions().polygon2)
        this.roomDrawer.enable();
      }
    },
    addDoor() {
      this.addDrawing("door", "icon-opened-door-aperture");
    },
    addStairs() {
      this.addDrawing("stairs", "icon-stairs");
    },
    addElevator() {
      this.addDrawing("elevator", "icon-elevator");
    },
    addEscalator() {
      this.addDrawing("escalator", "icon-escalator");
    },
    addDrawing(type, iconClass) {
      if (this.drawing) {
        this.drawing.remove();
        this.drawing._map = this.$refs.mapRef.$refs.mapRef.map;
        this.drawing = null;
        this.$refs.mapRef.$refs.mapRef.map.off('click');
      }
      if (this.roomDrawer) {
        this.roomDrawer.disable();
        this.roomDrawer = null;
      }
      this.drawing = new L.marker([51.505109712517786, -0.0826120376586914], {
        icon: mapComponents.icon({color: "red", iconClass: iconClass}),
        zIndexOffset: 1000,
      });
      this.drawing.properties = {
        type: type
      };
      this.drawing.addTo(this.$refs.mapRef.$refs.mapRef.map);
    },
    rayCasting(point, polygon) {
      var n = polygon.length,
          x = point[0],
          y = point[1];


      var i, j, c = false;
      for (i = 0, j = n - 1; i < n; j = i++) {
        if (((polygon[i][1] > y) !== (polygon[j][1] > y)) &&
            (x < (polygon[j][0] - polygon[i][0]) * (y - polygon[i][1]) / (polygon[j][1] - polygon[i][1]) + polygon[i][0]))
          c = !c;
      }
      return c;
    },
    mousemove(event) {
      if (this.drawing) {
        const icon = (() => {
          switch (this.drawing.properties.type) {
            case 'door':
              return 'icon-opened-door-aperture'
            case 'stairs':
              return 'icon-stairs'
            case 'escalator':
              return 'icon-escalator'
            case 'elevator':
              return 'icon-elevator'
            default:
              return ''
          }
        })();
        const successIcon = mapComponents.icon({color: "green", iconClass: icon});
        const errorIcon = mapComponents.icon({color: "red", iconClass: icon});
        this.drawing.setLatLng(event.latlng);
        const result = drawingRules[this.drawing.properties.type + 'Check'](event.latlng, this.floors[this.selectedFloor].editableLayers.getLayers())
        if (result.success) {
          this.drawing.setIcon(successIcon);
          this.$refs.mapRef.$refs.mapRef.map.on('click', () => {
            this.$refs.mapRef.$refs.mapRef.map.removeLayer(this.drawing)
            this.drawing.properties.name = this.getUniqueName(this.drawing.properties.type)
            this.drawing.properties.parent = result.parent;
            this.drawing.properties.success = true;
            this.drawing.bindTooltip(this.drawing.properties.name, {
              noHide: true,
              direction: 'auto'
            });
            this.floors[this.selectedFloor].editableLayers.addLayer(this.drawing)
            this.drawing = null
            this.$refs.mapRef.$refs.mapRef.map.off('click');
            this.configs();
          });
        } else {
          this.drawing.setIcon(errorIcon);
          this.drawing.properties.success = false;
          this.$refs.mapRef.$refs.mapRef.map.off('click');
        }
      }
    },
    layerPanelChange() {
      this.layersPanel = !this.layersPanel;
      const that = this;
      setTimeout(function () {
        that.$refs.mapRef.$refs.mapRef.map.invalidateSize()
      }, 400);
    },
    getSubs(parentName) {
      const subs = [];
      const layers = this.floors[this.selectedFloor].editableLayers.getLayers();
      const filteredLayers = layers.slice(1).filter(it => it.properties.parent);
      filteredLayers.forEach(layer => {
        const mainParent = this.getMainParent(layer, layers[0].properties.name);
        if (mainParent && mainParent.properties.name === parentName) {
          subs.push(layer);
        }
      });
      return subs;
    },
    getMainParent(layer, floorName) {
      const parent = layer.properties.parent;
      if (!parent) {
        return layer
      }
      if (parent.properties.parent && parent.properties.parent.properties.name !== floorName) {
        return this.getMainParent(parent.properties.parent)
      } else {
        return parent
      }
    },
    showLayer(layer) {
      const layers = [layer];
      var group = new L.featureGroup(layers);
      this.$refs.mapRef.$refs.mapRef.map.fitBounds(group.getBounds());
    },
    deleteLayer(layer) {
      const that = this;
      if (layer) {
        that.floors[that.selectedFloor].editableLayers.removeLayer(layer);
      } else {
        if (that.drawing) {
          that.drawing = null;
          that.$refs.mapRef.$refs.mapRef.map.off('click');
        }
        if (that.roomDrawer) {
          that.roomDrawer.disable();
          that.roomDrawer = null;
        }
        that.floors[that.selectedFloor].editableLayers.clearLayers();
      }
      setTimeout(function () {
        that.configs();
      }.bind(that), 100)
    },
    getUniqueName(type, number) {
      if (!number) {
        number = 1
      }
      const name = type + number;
      if (this.isUniqueName(name)) {
        return name
      } else {
        return this.getUniqueName(type, ++number)
      }
    },
    isUniqueName(name) {
      let isUnique = true;
      Object.values(this.floors).forEach(floor => {
        const layers = floor.editableLayers.getLayers();
        layers.forEach(layer => {
          if (layer.properties.name === name) {
            isUnique = false;
          }
        })
      })
      return isUnique
    },
    settingsDialog(layer) {
      this.layerEditDialogModel = {
        name: layer.properties.name,
        description: layer.properties.description,
        code: layer.properties.code
      };
      this.layerEditDialogModelTemp = layer;
      this.layerEditDialog = true;
    },
    submitEditLayer() {
      if (this.$refs.layerEditForm.validate() && this.layerEditDialogValid) {
        this.layerEditDialogModelTemp.properties.name = this.layerEditDialogModel.name;
        this.layerEditDialogModelTemp.properties.description = this.layerEditDialogModel.description;
        if (this.layerEditDialogModel.code) {
          this.layerEditDialogModelTemp.properties.code = this.layerEditDialogModel.code;
        }
        this.configs();
        this.layerEditDialog = false;
      }
    },
    fromJson() {
      const that = this;
      let result = pCode.floorsDataFromJson(this.json)
      if (result.success) {
        that.floors = result.floors;
        that.configs();
        that.jsonSuccess = true;
      } else {
        that.jsonSuccess = false;
      }
    },
    toJson(value) {
      return pCode.floorsDataToJson(value);
    },
    onResize() {
      const that = this;
      that.windowWidth = that.$refs.designArea.clientWidth;
      if (that.windowWidth > 585) {
        setTimeout(() => {
          if (that && that.$refs.mapRef) {
            that.$refs.mapRef.$refs.mapRef.map.off("click");
            that.configs();
          }
        }, 1000)
      }
    }
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      that.ro = new ResizeObserver(this.onResize)
      that.ro.observe(that.$refs.designArea)
    })
    that.onResize();
  },
  beforeUnmount() {
    this.ro.disconnect();
    this.ro = null;
  }
}
</script>

<style scoped>
.layers-open #left {
  width: 70% !important;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.layers-close #left {
  width: calc(100% - 12px) !important;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.layers-open #right {
  width: 30% !important;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.layers-close #right {
  width: 12px !important;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}
</style>