<template>
  <div>
    <v-snackbar
        v-model="snackbar"
        :timeout="3000"
    >
      {{ snackbarText }}

      <template v-slot:actions>
        <v-btn
            color="blue"
            variant="text"
            @click="snackbar = false"
        >
          {{ $t('grouping_routes.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <stepper-view
        :steps="[
      $t('grouping_routes.route_details'),
      $t('grouping_routes.vehicles'),
      $t('grouping_routes.waypoints'),
      $t('grouping_routes.grouping'),
      $t('grouping_routes.editing'),
      $t('grouping_routes.result')]"
        :step-conditions="{0:(centerLat != null && centerLon != null), 1: vehicles.length>0, 2:waypoints.length>0 && waypoints.length>=vehicles.length, 3:(result != null && result.geoJson != null)}"
        :onChange="onStepChange"
    >
      <template #step0>
        <div style="float: left; width:50%">
          <v-card-subtitle>{{ $t('grouping_routes.select_transportation_type') }}:
          </v-card-subtitle>
          <v-card-subtitle>
            <v-radio-group v-model="loadType"
                           class="text-purple"
                           inline>
              <v-radio :label="$t('grouping_routes.transportation_types.pieces')" value="0"></v-radio>
              <v-radio :label="$t('grouping_routes.transportation_types.desi')" value="1"></v-radio>
            </v-radio-group>
          </v-card-subtitle>
        </div>
        <v-card-subtitle style="float: right; width:50%">
          {{ $t('grouping_routes.use_all_vehicles') }}?
          <v-switch v-model="useAllVehicles"
                    :label="(useAllVehicles ? $t('grouping_routes.all_vehicles_will_be_used') : $t('grouping_routes.the_required_number_of_vehicles_will_be_used')) + '.'"/>
        </v-card-subtitle>
        <v-card-subtitle>{{ $t('grouping_routes.select_the_origin') }}:</v-card-subtitle>
        <selector-map class="ml-4 mt-1" style="height: 500px" :lat="centerLat" :lon="centerLon"
                      v-on:onMarkerChanged="(data)=>onCenterChange(data)"/>
      </template>
      <template #step1>
        <v-dialog
            v-model="addVehicleDialog"
            class="col-12 col-sm-12 col-md-6 col-lg-6"
        >
          <v-card>
            <div class="text-center mt-3">
              <v-btn @click="addVehicleFromSaved = !addVehicleFromSaved" rounded
                     style="background-color: mediumpurple; color: white">
                <v-btn style="width: calc(50% + 12px); position: absolute"
                       :class="'switch-back ' + (addVehicleFromSaved ? 'switch-back-right':'')" rounded
                       color="purple"/>
                <span class="align-items-center"
                      style="z-index: 1; display: grid; grid-template-columns: calc(50% - 8px) 16px calc(50% - 8px);">
            <span class="mr-2">{{ $t('grouping_routes.vehicle_dialog.new_vehicle') }}</span>
            <i :class="!addVehicleFromSaved ? 'fas fa-plus' : 'fas fa-save'" style="width:16px"/>
            <span class="ml-2">{{ $t('grouping_routes.vehicle_dialog.saved_vehicle') }}</span>
          </span>
              </v-btn>
            </div>
            <v-form ref="addVehicleForm"
                    v-if="!addVehicleFromSaved"
                    class="overflow-hidden d-flex align-items-center text-center"
                    style="flex-direction: column"
                    v-model="addVehicleDialogValidate"
                    @submit.prevent="addVehicle">
              <v-card-title>{{ $t('grouping_routes.add_vehicle') }}</v-card-title>
              <i class="fas fa-window-close" style="font-size: x-large;
              right: 5px;
              top: 5px;
              position: absolute;
              cursor: pointer"
                 @click="addVehicleDialog=false"/>
              <v-card-text class="w-75">
                <v-text-field
                    density="compact"
                    variant="solo"
                    :label="$t('grouping_routes.vehicle_dialog.plate')"
                    v-model="newVehicle.plate"
                    append-inner-icon="mdi-car"
                    single-line
                    :rules="[
                  v => !!v || 'Field is required'
                ]"
                    hide-details
                ></v-text-field>
                <v-text-field
                    density="compact"
                    variant="solo"
                    :label="$t('grouping_routes.vehicle_dialog.description')"
                    v-model="newVehicle.description"
                    append-inner-icon="mdi-account"
                    single-line
                    hide-details
                    class="mt-1"
                ></v-text-field>
                <v-text-field
                    density="compact"
                    variant="solo"
                    :label="$t('grouping_routes.vehicle_dialog.capacity')"
                    v-model="newVehicle.capacity"
                    append-inner-icon="mdi-warehouse"
                    single-line
                    hide-details
                    :rules="[
                    v => !!v || 'Field is required'
                  ]"
                    type="number"
                    class="mt-1"
                ></v-text-field>
                <v-checkbox v-model="addVehicleSave"
                            :label="$t('grouping_routes.vehicle_dialog.save_to_database')"></v-checkbox>

                <v-alert
                    v-if="alert.message"
                    :type="alert.type"
                    style="padding-bottom: 5px; padding-top: 5px;"
                >{{ alert.message }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn type="submit" color="purple" block>{{ $t('grouping_routes.vehicle_dialog.save') }}</v-btn>
              </v-card-actions>
            </v-form>
            <div
                v-else>
              <v-select
                  clearable
                  chips
                  v-model="selectedVehicles"
                  :label="$t('grouping_routes.vehicle_dialog.select_vehicles')"
                  :items="typedVehicles.filter(it=>vehicles.filter(it2=> it.plate == it2.plate).length == 0)"
                  multiple
                  return-object
                  class="m-3"
                  variant="solo"
                  item-title="plate"
                  :no-data-text="$t('grouping_routes.vehicle_dialog.there_are_no_vehicles')"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      title="Select All"
                      @click="toggle2"
                  >
                    <template v-slot:prepend>
                      <v-checkbox-btn
                          :color="selectedVehicles.length === typedVehicles.filter(it=>vehicles.filter(it2=> it.plate == it2.plate).length == 0).length ? 'indigo-darken-4' : undefined"
                          :indeterminate="selectedVehicles.length === typedVehicles.filter(it=>vehicles.filter(it2=> it.plate == it2.plate).length == 0).length"
                          :model-value="selectedVehicles.length === typedVehicles.filter(it=>vehicles.filter(it2=> it.plate == it2.plate).length == 0).length"
                      ></v-checkbox-btn>
                    </template>
                  </v-list-item>

                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
              <v-card-actions>
                <v-btn color="purple" @click="addSelectedVehicles" block>{{
                    $t('grouping_routes.vehicle_dialog.add')
                  }}
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <v-row class="col-12">
          <div v-for="vehicle in vehicles" v-bind:key="vehicle.plate"
               class="border-white bg-purple justify-content-center align-items-center d-flex overflow-auto p-2 col-lg-4 col-md-6 col-sm-12 position-relative"
               style="height: 150px; border-width: 5px; border-style: solid; flex-direction: column;">
            <div class="text-end w-100 pr-2 pt-1 fa-xs position-absolute top-0" role="button"
                 @click="vehicles = vehicles.filter(it=> it.plate != vehicle.plate)">
              {{ $t('grouping_routes.remove') }} <i class="fas fa-trash"/></div>
            <v-card-title class="white--text"
                          style="margin-top: 0 !important; width: 100%; display: flex; justify-content: space-between; align-items: center;">
              <v-avatar size="56" style="background: white">
                <v-img height="40"
                       alt="vehicle-img"
                       src="@/assets/template/images/bus.png"/>
              </v-avatar>
              <span class="ml-3">
                {{ vehicle.plate }}
              </span>
            </v-card-title>
            <v-card-subtitle class="bg-white w-100">
              {{ $t('grouping_routes.description') }}: {{ vehicle.description }}
              <br/>
              {{ $t('grouping_routes.capacity') }}: {{ vehicle.capacity }}
              {{ loadType == false ? $t('grouping_routes.pieces') : $t('grouping_routes.desi') }}
            </v-card-subtitle>
          </div>
          <div
              class="border-white bg-purple justify-content-center align-items-center d-flex overflow-auto col-12 col-lg-4 col-md-6 col-sm-12"
              style="height: 150px; border-width: 5px; border-style: solid; cursor: pointer;"
              @click="getVehicles();addVehicleDialog=true;">
            <v-card-title class="white--text mt-8 text-center" style="margin-top: 0 !important;">
              <div class="ml-3">
                <i class='bx bxs-add-to-queue'></i>
              </div>
              <div class="ml-3">
                {{ $t('grouping_routes.add_vehicle') }}
              </div>
            </v-card-title>
          </div>
        </v-row>
      </template>
      <template #step2>
        <v-dialog
            v-model="addWaypointDialog"
            class="w-100"
            style="max-width: 1000px"
        >
          <v-card>
            <div class="text-center mt-3">
              <v-btn @click="addWaypointFromSaved = !addWaypointFromSaved" rounded
                     style="background-color: mediumpurple; color: white">
                <v-btn style="width: calc(50% + 12px); position: absolute"
                       :class="'switch-back ' + (addWaypointFromSaved ? 'switch-back-right':'')" rounded
                       color="purple"/>
                <span class="align-items-center"
                      style="z-index: 1; display: grid; grid-template-columns: calc(50% - 8px) 16px calc(50% - 8px);">
            <span class="mr-2">{{ $t('grouping_routes.waypoint_dialog.new_waypoint') }}</span>
            <i :class="!addWaypointFromSaved ? 'fas fa-plus' : 'fas fa-save'" style="width:16px"/>
            <span class="ml-2">{{ $t('grouping_routes.waypoint_dialog.saved_waypoint') }}</span>
          </span>
              </v-btn>
            </div>
            <v-form v-if="!addWaypointFromSaved" v-model="addWaypointDialogValidate"
                    class="overflow-auto d-flex align-items-center text-center col-12 dialog-card"
                    @submit.prevent="addWaypoint">
              <v-card-title>{{ $t('grouping_routes.waypoint_dialog.add_waypoint') }}</v-card-title>
              <i class="fas fa-window-close" style="font-size: x-large;
              right: 5px;
              top: 5px;
              position: absolute;
              cursor: pointer"
                 @click="addWaypointDialog=false"/>
              <v-row class="col-12">
                <v-card-text class="col-12 col-lg-6 col-md-6 col-sm-12">
                  <v-text-field
                      density="compact"
                      variant="solo"
                      :label="$t('grouping_routes.waypoint_dialog.title')"
                      v-model="newWaypoint.title"
                      append-inner-icon="mdi-format-title"
                      single-line
                      :rules="[
                  v => !!v || 'Field is required'
                ]"
                      hide-details
                  ></v-text-field>
                  <v-text-field
                      density="compact"
                      variant="solo"
                      :label="$t('grouping_routes.waypoint_dialog.description')"
                      v-model="newWaypoint.description"
                      append-inner-icon="mdi-note-text"
                      single-line
                      hide-details
                      class="mt-1"
                  ></v-text-field>
                  <v-text-field
                      density="compact"
                      variant="solo"
                      :label="$t('grouping_routes.waypoint_dialog.load')"
                      v-model="newWaypoint.load"
                      append-inner-icon="mdi-warehouse"
                      single-line
                      hide-details
                      :rules="[
                    v => !!v || 'Field is required'
                  ]"
                      type="number"
                      class="mt-1"
                  ></v-text-field>
                  <v-text-field
                      density="compact"
                      variant="solo"
                      :label="$t('grouping_routes.waypoint_dialog.coordinate_latitude')"
                      v-model="lat"
                      @input="changeLat($event.target.value)"
                      append-inner-icon="mdi-latitude"
                      single-line
                      hide-details
                      :rules="[
                    v => !!v || 'Field is required'
                  ]"
                      type="number"
                      class="mt-1"
                  ></v-text-field>
                  <v-text-field
                      density="compact"
                      variant="solo"
                      :label="$t('grouping_routes.waypoint_dialog.coordinate_longitude')"
                      v-model="lon"
                      @input="changeLon($event.target.value)"
                      append-inner-icon="mdi-longitude"
                      single-line
                      hide-details
                      :rules="[
                    v => !!v || 'Field is required'
                  ]"
                      type="number"
                      class="mt-1"
                  ></v-text-field>
                  <v-text-field
                      density="compact"
                      variant="solo"
                      :label="$t('grouping_routes.waypoint_dialog.address')"
                      v-model="newWaypoint.address"
                      append-inner-icon="mdi-map-marker"
                      single-line
                      hide-details
                      class="mt-1"
                  ></v-text-field>
                  <v-checkbox v-model="addWaypointSave"
                              :label="$t('grouping_routes.waypoint_dialog.save_to_database')"></v-checkbox>
                </v-card-text>
                <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                  <selector-map :lat="lat" :lon="lon" v-on:onMarkerChanged="(data)=>onMarkerChange(data)"/>
                </div>
              </v-row>
              <v-card-actions>
                <v-btn type="submit" color="purple" block>{{ $t('grouping_routes.waypoint_dialog.save') }}</v-btn>
              </v-card-actions>
            </v-form>
            <div
                v-else>
              <v-select
                  clearable
                  chips
                  v-model="selectedWaypoints"
                  :label="$t('grouping_routes.waypoint_dialog.select_waypoints')"
                  :items="allWaypoints.filter(it=>waypoints.filter(it2=> it.id == it2.id).length == 0)"
                  multiple
                  return-object
                  class="m-3"
                  variant="solo"
                  item-title="title"
                  :no-data-text="$t('grouping_routes.waypoint_dialog.there_are_no_waypoints')"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      title="Select All"
                      @click="toggle"
                  >
                    <template v-slot:prepend>
                      <v-checkbox-btn
                          :color="selectedWaypoints.length === allWaypoints.length ? 'indigo-darken-4' : undefined"
                          :indeterminate="selectedWaypoints.length === allWaypoints.length"
                          :model-value="selectedWaypoints.length === allWaypoints.length"
                      ></v-checkbox-btn>
                    </template>
                  </v-list-item>

                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
              <v-card-actions>
                <v-btn color="purple" @click="addSelectedWaypoints" block>
                  {{ $t('grouping_routes.waypoint_dialog.add') }}
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
        <div class="d-flex justify-space-between align-items-center">
          <v-card-subtitle>{{ $t('grouping_routes.waypoints_not_less_than_vehicles') }}</v-card-subtitle>
          <v-btn color="purple" class="m-2" @click="getWaypoints();addWaypointDialog=true;">+ {{
              $t('database.add_new')
            }}
          </v-btn>
        </div>
        <div class="d-flex justify-content-end">
          <v-card v-if="selectedWaypointMarker" class="position-absolute m-2" style="z-index: 9999999;">
            <div class="marker-vertical-menu position-relative">
              <div class="pl-1 text-end position-absolute" role="button" @click="selectedWaypointMarker = null"><i
                  class="fas fa-window-close"/></div>
              <div class="pl-1 text-center">{{ selectedWaypointMarker.waypoint.title }}</div>
              <v-card-subtitle class="pl-1">
                {{ selectedWaypointMarker.waypoint.load }} {{ (loadType == '0' ? 'adet' : 'desi') }}
              </v-card-subtitle>
              <v-card-subtitle class="pl-1">{{ selectedWaypointMarker.waypoint.description }}</v-card-subtitle>
              <v-card-subtitle class="pl-1">{{ selectedWaypointMarker.waypoint.coordinate }}</v-card-subtitle>
              <v-btn @click="removeWaypoint(selectedWaypointMarker)" class="pl-1 w-100 mt-1 bg-purple"
                     style="height: 25px">
                {{ $t('grouping_routes.remove') }}
              </v-btn>
            </div>
          </v-card>
          <map-container style="height: calc(80vh - 215px);" ref="waypointsMap"/>
        </div>
      </template>
      <template #step3>
        <div v-if="feeValidation">
          <v-alert
              color="deep-orange"
              icon="mdi-alert"
              title="Bu işlem için hesabınızdan kredi tahsil edilecektir"
              variant="outlined"
          >
            <div class="d-flex flex-column align-center justify-space-between">
              <div class="text-start w-100">
                Bu işlem için hesabınızdan <b>{{ waypoints.length * vehicles.length }}</b> kredi düşülecektir. Devam
                etmek istiyor musunuz?
              </div>
              <v-row class="m-1">
                <v-btn
                    color="error"
                    variant="outlined"
                    @click="refreshComponent"
                >
                  İPTAL
                </v-btn>

                <v-btn
                    color="success"
                    variant="outlined"
                    class="ml-sm-2"
                    @click="feeAgreed"
                >
                  TAMAM
                </v-btn>
              </v-row>
            </div>
          </v-alert>
        </div>
        <div v-else-if="loading" class="text-center">
          <v-progress-circular
              indeterminate
              color="purple"
          ></v-progress-circular>
        </div>
        <map-container ref="groupingMap" v-else style="height: 300px"/>
      </template>
      <template #step4>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <div class="border p-1">
              <v-card-title>{{ $t('grouping_routes.move') }}</v-card-title>
              <v-form ref="move" @submit.prevent="moveWaypoint();">
                <v-select
                    :items="waypoints"
                    v-model="moveWaypointModel"
                    :label="$t('grouping_routes.select_waypoint')"
                    density="compact"
                    item-title="title"
                    return-object
                ></v-select>
                <v-select
                    :items="vehicles.filter(it=>!result || (!result.waypoints[it.id] || result.waypoints[it.id].filter(it2=>moveWaypointModel && it2.id === moveWaypointModel.id).length===0))"
                    v-model="moveVehicleModel"
                    :disabled="!moveWaypointModel"
                    :label="$t('grouping_routes.select_vehicle')"
                    density="compact"
                    item-title="plate"
                    return-object
                ></v-select>
                <v-btn type="submit" color="purple" block>{{ $t('grouping_routes.move') }}</v-btn>
              </v-form>
            </div>
            <div class="border p-1 mt-1">
              <v-card-title>{{ $t('grouping_routes.substitution') }}</v-card-title>
              <v-form ref="substitution" @submit.prevent="substitutionWaypoint();">
                <v-select
                    :items="waypoints"
                    v-model="substitutionWaypointModel1"
                    :label="$t('grouping_routes.select_waypoint')"
                    density="compact"
                    item-title="title"
                    return-object
                ></v-select>
                <v-select
                    :items="waypoints.filter(it=>substitutionWaypointModel1 && it.id !== substitutionWaypointModel1.id)"
                    v-model="substitutionWaypointModel2"
                    :label="$t('grouping_routes.select_waypoint')"
                    :disabled="!substitutionWaypointModel1"
                    density="compact"
                    item-title="title"
                    return-object
                ></v-select>
                <v-btn type="submit" color="purple" block>{{ $t('grouping_routes.substitute') }}</v-btn>
              </v-form>
            </div>
          </v-col>
          <v-col cols="12" sm="6" md="8" style="height: auto; min-height: 300px">
            <map-container ref="editingMap"/>
          </v-col>
        </v-row>
      </template>
      <template #step5>
        <map-container ref="resultMap" style="height: 200px"/>
        <v-row class="mt-2">
          <div v-for="vehicleId in Object.keys(result.waypoints)" v-bind:key="vehicleId"
               class="p-1  col-lg-4 col-md-6 col-sm-12">
            <div
                class="w-100 border-white bg-purple justify-content-center align-items-center d-flex overflow-auto p-0 m-0 position-relative"
                style="border-width: 0; border-style: solid; flex-direction: column;">
              <a class="d-flex w-100 justify-space-between pl-4 pr-4">
                <a :href="createVehicleRouteUrl(vehicleId)" target="_blank" class="text-light-blue-accent-1 pr-12"
                   style="width: auto; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ createVehicleRouteUrl(vehicleId) }}</a>
                <a style="cursor: copy; white-space: nowrap"
                   :class="'text-end '
                  + (copiedVehicleId === vehicleId ? ' text-light-green-accent-1' : ' text-light-blue-accent-1')"
                   @click="copyVehicleRouteUrl(vehicleId)">
                  {{ $t('grouping_routes.copy_link') }} <i class="fas fa-link"/>
                </a>
              </a>
              <v-card-title class="white--text"
                            style="margin-top: 0 !important; width: 100%; display: flex; justify-content: space-between; align-items: center;">
                <v-avatar size="56" style="background: white">
                  <v-img height="40"
                         alt="araç"
                         src="@/assets/template/images/bus.png"/>
                </v-avatar>
                <span class="ml-3">
                {{ vehicles.find(it => it.id === Number(vehicleId)).plate }}
              </span>
              </v-card-title>
              <v-card-subtitle class="bg-white w-100">
                {{ $t('grouping_routes.description') }}: {{
                  vehicles.find(it => it.id === Number(vehicleId)).description
                }}
                <br/>
                {{ $t('grouping_routes.capacity') }}: {{ vehicles.find(it => it.id === Number(vehicleId)).capacity }}
                {{ loadType == false ? $t('grouping_routes.pieces') : $t('grouping_routes.desi') }}
              </v-card-subtitle>
            </div>
            <v-card-subtitle class="border bg-white w-auto overflow-auto" style="height: 100px">
              <div v-for="waypoint in result.waypoints[vehicleId]" v-bind:key="waypoint.id">
                <i class="mdi mdi-chevron-right text-purple"/> {{ waypoint.title }}
              </div>
            </v-card-subtitle>
          </div>
        </v-row>
      </template>
    </stepper-view>
  </div>
</template>

<script>
import StepperView from "@/components/Stepper";
import actions from "@/api/vehicle"
import actionsWaypoint from "@/api/waypoint"
import actionsUser from "@/api/user"
import grouping from "@/api/grouping"
import SelectorMap from "@/components/SelectorMap";
import MapContainer from "@/components/MapContainer";
import GeoJson from "@/constaints/geoJson";
import MapComponents from "@/components/mapComponents";

const L = window.L;
export default {
  name: "GroupingRouteContainer",
  components: {
    MapContainer,
    SelectorMap,
    StepperView
  },
  data: () => ({
    useAllVehicles: false,
    selectedWaypointMarker: null,
    snackbar: false,
    snackbarText: "",
    result: null,
    loading: true,
    loadType: "0",
    centerLat: null,
    centerLon: null,
    typedVehicles: [],
    addVehicleDialog: false,
    addVehicleDialogValidate: false,
    addVehicleFromSaved: false,
    addVehicleSave: false,
    selectedVehicles: [],
    newVehicle: {
      plate: null,
      description: null,
      capacity: null,
      user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
      type: null
    },
    vehicles: [],
    addWaypointFromSaved: false,
    addWaypointDialog: false,
    addWaypointSave: false,
    selectedWaypoints: [],
    allWaypoints: [],
    addWaypointDialogValidate: false,
    lat: null,
    lon: null,
    newWaypoint: {
      user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
      title: null,
      description: null,
      coordinate: null,
      address: null,
      load: null
    },
    waypoints: [],
    substitutionWaypointModel1: null,
    substitutionWaypointModel2: null,
    moveWaypointModel: null,
    moveVehicleModel: null,
    copiedVehicleId: null,
    alert: {
      type: "",
      message: ""
    },
    feeValidation: false,
    lastRequest: null
  }),
  methods: {
    toggle() {
      if (this.selectedWaypoints.length === this.allWaypoints.length) {
        this.selectedWaypoints = []
      } else {
        this.selectedWaypoints = this.allWaypoints.slice()
      }
    },
    toggle2() {
      if (this.selectedVehicles.length === this.typedVehicles.filter(it=>this.vehicles.filter(it2=> it.plate == it2.plate).length == 0).length) {
        this.selectedVehicles = []
      } else {
        this.selectedVehicles = this.typedVehicles.filter(it=>this.vehicles.filter(it2=> it.plate == it2.plate).length == 0).slice()
      }
    },
    async addVehicle() {
      if (this.addVehicleDialogValidate) {
        this.newVehicle.type = Number(this.loadType);
        if (this.addVehicleSave) {
          let result;
          try {
            result = await actions.save(this.newVehicle);
          } catch (err) {
            result = err;
          }
          if (result.data) {
            this.alert.type = "success";
            this.alert.message = this.$t('vehicle.success');
            this.vehicles.push(result.data);
            this.addVehicleDialog = false;
            this.newVehicle = {
              plate: null,
              description: null,
              capacity: null,
              user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
              type: null
            };
          } else if (result.response) {
            const errorMessage = this.getMessageFromResponse(result.response.data.message);
            this.alert.type = "error";
            this.alert.message = errorMessage;
          } else {
            this.alert.type = "error";
            this.alert.message = this.$t('vehicle.something_went_wrong');
          }
        } else {
          let id = -1;
          this.vehicles.forEach(it => {
            if (it.id < 0) {
              id--;
            }
          })
          this.newVehicle.id = id;
          this.newVehicle.user = JSON.parse(localStorage.getItem("user"));
          this.vehicles.push(this.newVehicle);
          this.addVehicleDialog = false;
          this.newVehicle = {
            plate: null,
            description: null,
            capacity: null,
            user: "/api/user/" + JSON.parse(localStorage.getItem("user")).id,
            type: null
          };
        }
      }
    },
    async getVehicles() {
      this.typedVehicles = await actions.findAllByUserIdAndType(Number(this.loadType));
    },
    addSelectedVehicles() {
      this.vehicles = this.vehicles.concat(this.selectedVehicles);
      this.selectedVehicles = [];
      this.addVehicleDialog = false;
    },
    addSelectedWaypoints() {
      this.waypoints = this.waypoints.concat(this.selectedWaypoints);
      this.selectedWaypoints = [];
      this.addWaypointDialog = false;
      this.addWaypointsToMap();
    },
    async addWaypoint() {
      if (this.addWaypointDialogValidate) {
        this.newWaypoint.coordinate = JSON.stringify([this.lat, this.lon]);
        if (this.addWaypointSave) {
          const waypoint = await actionsWaypoint.save(this.newWaypoint);
          this.waypoints.push(waypoint.data);
        } else {
          let id = -1;
          this.waypoints.forEach(it => {
            if (it.id < 0) {
              id--;
            }
          })
          this.newWaypoint.id = id;
          this.newWaypoint.user = JSON.parse(localStorage.getItem("user"));
          this.waypoints.push(this.newWaypoint);
        }
        this.newWaypoint = {
          user: "/api/waypoint/" + JSON.parse(localStorage.getItem("user")).id,
          title: null,
          description: null,
          coordinate: null,
          address: null,
          load: null
        }
        this.addWaypointDialog = false;
        this.addWaypointsToMap();
      }
    },
    async getWaypoints() {
      this.allWaypoints = await actionsWaypoint.findAllByUserId(JSON.parse(localStorage.getItem("user")).id);
    },
    onMarkerChange(data) {
      var coordinates = data.coordinates;
      this.newWaypoint.address = data.address;
      this.lat = coordinates.lat;
      this.lon = coordinates.lng;
    },
    onCenterChange(data) {
      var coordinates = data.coordinates;
      this.centerLat = coordinates.lat;
      this.centerLon = coordinates.lng;
    },
    changeLon(val) {
      this.lon = val;
      SelectorMap.watch.lon1(this.lon);
    },
    changeLat(val) {
      this.lat = val;
      SelectorMap.watch.lat1(this.lat);
    },
    addWaypointsToMap() {
      const that = this;
      const markers = [];
      this.waypoints.forEach(it => {
        const coordinates = JSON.parse(it.coordinate);
        let marker = L.marker(coordinates, {
          icon: MapComponents.icon(),
        });
        marker.waypoint = it;
        marker = marker.on('click', function onClick() {
          that.selectedWaypointMarker = marker;
          that.$refs.waypointsMap.map.on('click', function () {
            that.selectedWaypointMarker = null;
          });
        });
        markers.push(marker);
      });
      if (markers.length > 0) {
        this.$refs.waypointsMap.addMarkers(markers);
      }
    },
    onStepChange(newVal, oldVal) {
      if (newVal === 3 && oldVal === 2) {
        const request = {
          center: {
            coordinate: [this.centerLat, this.centerLon]
          },
          vehicles: this.vehicles,
          waypoints: this.waypoints,
          useAllVehicles: this.useAllVehicles
        }
        if (this.result && this.result.geoJson && JSON.stringify(request) === JSON.stringify(this.lastRequest)) {
          let geoJson = this.result.geoJson;
          this.loading = false;
          const features = GeoJson.geoJsonToFeatures(geoJson);
          setTimeout(() => {
            features.forEach(it => {
              it.addTo(this.$refs.groupingMap.map);
            })
            var group = new L.featureGroup(features)
            this.$refs.groupingMap.map.fitBounds(group.getBounds());
          }, 100);
        } else {
          this.askForGroupingFee();
        }
      } else if (newVal === 3) {
        if (this.result && this.result.geoJson) {
          let geoJson = this.result.geoJson;
          this.loading = false;
          const features = GeoJson.geoJsonToFeatures(geoJson);
          setTimeout(() => {
            features.forEach(it => {
              it.addTo(this.$refs.groupingMap.map);
            })
            var group = new L.featureGroup(features)
            this.$refs.groupingMap.map.fitBounds(group.getBounds());
          }, 100);
        }
      } else if (newVal === 4 && this.result && this.result.geoJson) {
        const features = GeoJson.geoJsonToFeatures(this.result.geoJson);
        setTimeout(() => {
          features.forEach(it => {
            it.addTo(this.$refs.editingMap.map);
          })
          const group = new L.featureGroup(features);
          this.$refs.editingMap.map.fitBounds(group.getBounds());
        }, 100);
      } else if (newVal === 5) {
        const features1 = GeoJson.geoJsonToFeatures(this.result.geoJson);
        setTimeout(() => {
          features1.forEach(it => {
            it.addTo(this.$refs.resultMap.map);
          })
          const group = new L.featureGroup(features1);
          this.$refs.resultMap.map.fitBounds(group.getBounds());
        }, 100);
      } else if (newVal === 2) {
        setTimeout(() => {
          if (this.$refs.waypointsMap) {
            this.addWaypointsToMap();
          }
        }, 300)
      }
    },
    askForGroupingFee() {
      this.feeValidation = true;
    },
    async getGrouping() {
      const request = {
        center: {
          coordinate: [this.centerLat, this.centerLon]
        },
        vehicles: this.vehicles,
        waypoints: this.waypoints,
        useAllVehicles: this.useAllVehicles
      }
      this.lastRequest = request;
      const result = await grouping.grouping(request);
      this.result = result;
      const resultUser = (await actionsUser.findById(JSON.parse(localStorage.getItem("user")).id)).data
      localStorage.setItem("user", JSON.stringify(resultUser))
      if (result.success) {
        let geoJson = result.geoJson;
        this.loading = false;
        var features = GeoJson.geoJsonToFeatures(geoJson);
        setTimeout(() => {
          features.forEach(it => {
            it.addTo(this.$refs.groupingMap.map);
          })
          var group = new L.featureGroup(features)
          this.$refs.groupingMap.map.fitBounds(group.getBounds());
        }, 100);
      } else {
        this.snackbarText = result.message;
        this.snackbar = true;
      }
    },
    async getRoute() {
      var request = {
        calculateTSP: {
          center: {
            coordinate: [this.centerLat, this.centerLon]
          },
          vehicles: this.vehicles,
          waypoints: JSON.parse(JSON.stringify(this.waypoints)).map(way => {
            way.coordinate = [Number(JSON.parse(way.coordinate)[0]), Number(JSON.parse(way.coordinate)[1])];
            return way
          }),
        },
        data: this.result.waypoints,
        transactionId: this.result.transactionId
      }
      this.result.geoJson = (await grouping.route(request));
      var features = GeoJson.geoJsonToFeatures(this.result.geoJson);
      var that = this;
      setTimeout(() => {
        that.$refs.editingMap.map.eachLayer(function (layer) {
          if (!(layer instanceof L.TileLayer)) {
            that.$refs.editingMap.map.removeLayer(layer);
          }
        });
        features.forEach(it => {
          it.addTo(this.$refs.editingMap.map);
        })
        var group = new L.featureGroup(features)
        this.$refs.editingMap.map.fitBounds(group.getBounds());
      }, 100);
    },
    substitutionWaypoint() {
      let waypointToVehicles = this.result.waypoints;
      let differenceLoad = this.substitutionWaypointModel1.load - this.substitutionWaypointModel2.load;

      let way1 = JSON.parse(JSON.stringify(this.substitutionWaypointModel1));
      way1.coordinate = [Number(JSON.parse(way1.coordinate)[0]), Number(JSON.parse(way1.coordinate)[1])];
      let way2 = JSON.parse(JSON.stringify(this.substitutionWaypointModel2));
      way2.coordinate = [Number(JSON.parse(way2.coordinate)[0]), Number(JSON.parse(way2.coordinate)[1])];

      let lowLoadWaypoint = differenceLoad < 0 ? this.substitutionWaypointModel1 : this.substitutionWaypointModel2;
      let lowLoadVehicleId = Object.entries(waypointToVehicles).find(it => it[1].filter(it2 => it2.id === lowLoadWaypoint.id).length > 0)[0];

      let totalLoad = 0;
      waypointToVehicles[lowLoadVehicleId].forEach(it => totalLoad += it.load);
      let capacity = this.vehicles.filter(it => it.id === Number(lowLoadVehicleId))[0].capacity;

      if (differenceLoad === 0 || ((totalLoad + Math.abs(differenceLoad)) <= capacity)) {
        let firstVehicleId = Object.entries(waypointToVehicles).find(it => it[1].filter(it2 => it2.id === this.substitutionWaypointModel1.id).length > 0)[0];
        let secondVehicleId = Object.entries(waypointToVehicles).find(it => it[1].filter(it2 => it2.id === this.substitutionWaypointModel2.id).length > 0)[0];
        this.result.waypoints[firstVehicleId] = waypointToVehicles[firstVehicleId].filter(it => it.id !== this.substitutionWaypointModel1.id);
        this.result.waypoints[secondVehicleId] = waypointToVehicles[firstVehicleId].filter(it => it.id !== this.substitutionWaypointModel1.id);
        this.result.waypoints[firstVehicleId].push(way2);
        this.result.waypoints[secondVehicleId].push(way1);
        this.getRoute();
        this.$refs.substitution.reset();
      } else {
        this.snackbarText = this.$t('grouping_routes.the_capacity_of_one_vehicles_is_insufficient');
        this.snackbar = true;
      }
    },
    moveWaypoint() {
      let waypointToVehicles = this.result.waypoints;
      let totalLoad = 0;
      if (waypointToVehicles[this.moveVehicleModel.id]) {
        waypointToVehicles[this.moveVehicleModel.id].forEach(it => totalLoad += it.load);
      }
      let exVehicle = Object.entries(waypointToVehicles).find(it => it[1].filter(it2 => it2.id === this.moveWaypointModel.id).length > 0)[0]
      if ((totalLoad + this.moveWaypointModel.load) > this.moveVehicleModel.capacity) {
        this.snackbarText = this.$t('grouping_routes.insufficient_capacity_of_the_selected_vehicle');
        this.snackbar = true;
      } else {
        this.result.waypoints[exVehicle] = waypointToVehicles[exVehicle].filter(it => it.id !== this.moveWaypointModel.id);
        var way = JSON.parse(JSON.stringify(this.moveWaypointModel));
        way.coordinate = [Number(JSON.parse(way.coordinate)[0]), Number(JSON.parse(way.coordinate)[1])];
        if (!this.result.waypoints[this.moveVehicleModel.id]) {
          this.result.waypoints[this.moveVehicleModel.id] = []
        }
        this.result.waypoints[this.moveVehicleModel.id].push(way);
        this.getRoute();
        this.$refs.move.reset();
      }
    },
    createVehicleRouteUrl(vehicleId) {
      let baseUrl = "https://www.google.com/maps/dir/?api=1";
      let origin = this.centerLat + "," + this.centerLon;
      let url = baseUrl + "&origin=" + origin + "&destination=" + origin + "&travelmode=driving&waypoints=";
      this.result.waypoints[vehicleId].forEach(it => {
        url += (it.coordinate[0] + "," + it.coordinate[1]) + "|";
      });
      url = url.slice(0, url.length - 2);
      return url;
    },
    copyVehicleRouteUrl(vehicleId) {
      let url = this.createVehicleRouteUrl(vehicleId);
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(url);
      } else {
        this.unsecuredCopyToClipboard(url);
      }
      this.copiedVehicleId = vehicleId;
      setTimeout((() => {
        this.copiedVehicleId = null;
      }), 2000)
    },
    unsecuredCopyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        // eslint-disable-next-line no-empty
      } catch (err) {
      }
      document.body.removeChild(textArea);
    },
    getMessageFromResponse(response) {
      if (response.includes("vehicles_vehicle_plate")) {
        return this.$t('vehicle.plate_exist');
      } else {
        return this.$t('vehicle.something_went_wrong');
      }
    },
    removeWaypoint(marker) {
      this.waypoints.pop(marker)
      this.removeMarker(marker)
    },
    removeMarker(marker) {
      this.$refs.waypointsMap.map.removeLayer(marker);
      this.selectedWaypointMarker = null;
    },
    refreshComponent() {
      window.location.reload();
    },
    feeAgreed() {
      this.feeValidation = false;
      setTimeout(() => {
        this.getGrouping();
      }, 300)
    }
  },
}
</script>

<style scoped>
.switch-back {
  left: 0;
  transition: all 0.2s linear;
}

.switch-back-right {
  left: calc(50% - 12px);
}

.dialog-card {
  flex-direction: column;
  max-height: 80vh;
}

.marker-vertical-menu {
  width: 200px; /* Set a width if you like */
}

.marker-vertical-menu a {
  background-color: #eee; /* Grey background color */
  color: black; /* Black text color */
  display: block; /* Make the links appear below each other */
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove underline from links */
}

.marker-vertical-menu a:hover {
  background-color: #ccc; /* Dark grey background on mouse-over */
}

.marker-vertical-menu a.active {
  background-color: #04AA6D; /* Add a green color to the "active/current" link */
  color: white;
}
</style>