<template>
  <div>
    <div class="p-5">
      <div class="mx-auto col-12 col-lg-5">
        <v-card class="pa-5" color="white">
          <v-form v-model="valid" ref="form" @submit.prevent="">
            <div class="text-center mb-3">
              <v-btn @click="formData.isPersonal = !formData.isPersonal" rounded
                     style="background-color: mediumpurple; color: white">
                <v-btn style="width: calc(50% + 12px); position: absolute"
                       :class="'switch-back ' + (!formData.isPersonal ? 'switch-back-right':'')" rounded
                       color="purple"/>
                <span class="align-items-center"
                      style="z-index: 1; display: grid; grid-template-columns: calc(50% - 8px) 16px calc(50% - 8px);">
            <span class="mr-2">{{ $t('registration.personal') }}</span>
            <i :class="formData.isPersonal ? 'fas fa-user' : 'fas fa-building'" style="width:16px"/>
            <span class="ml-2">{{ $t('registration.corporate') }}</span>
          </span>
              </v-btn>
            </div>
            <v-text-field
                outlined
                v-model="formData.name"
                :label="$t('registration.name')"
                :rules="rules.nameRules"
                required
                color="purple"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="formData.surname"
                :rules="rules.surnameRules"
                :label="$t('registration.surname')"
                required
                color="purple"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="formData.username"
                :label="$t('registration.username')"
                :rules="rules.usernameRules"
                required
                color="purple"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="password"
                :label="$t('registration.password')"
                :rules="rules.passwordRules"
                :append-icon="!show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                required
                color="purple"
            ></v-text-field>
            <v-text-field
                outlined
                v-model="passwordConfirm"
                :label="$t('registration.password_confirm')"
                type="password"
                :rules="password === passwordConfirm ? rules.nullRules : rules.confirmPasswordRules"
                required
                color="purple"
            ></v-text-field>
            <div
                :class="'teldiv ' + (!(this.telValid.valid !== false && this.telValid.formatted === this.formData.phone) ? 'tel-error' : '')"
                :data-content="$t('rules.invalid_phone_number')">
              <vue-tel-input
                  mode="international"
                  v-model="formData.phone"
                  :label="$t('registration.phone_number')"
                  :inputOptions="{
                    'autocomplete':'on',
                    'autofocus': false,
                    'aria-describedby': '',
                    'id':'',
                    'maxlenght':25,
                    'name': 'telephone',
                    'showDialCode':false,
                    'placeholder': $t('registration.phone_number'),
                    'readonly': false,
                    'required': false,
                    'tabindex': 0,
                    'type': 'tel',
                    'styleClasses': ''
                  }"
                  @validate="telValidate"
              ></vue-tel-input>
            </div>
            <v-text-field
                outlined
                v-model="formData.email"
                :rules="rules.emailRules"
                :label="$t('registration.email')"
                color="purple"
            ></v-text-field>

            <v-row>
              <div class="mx-auto w-50 mt-3">
                <v-btn color="purple" dark class="w-100" @click="saveFormData">
                  {{ $t('registration.register') }}
                </v-btn>
              </div>
            </v-row>
            <v-spacer style="height: 30px"/>
            <v-alert
                v-if="alert.message"
                :type="alert.type"
                style="padding-bottom: 5px; padding-top: 5px;"
            >{{ alert.message }}
            </v-alert>
          </v-form>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import rules from "@/constaints/rules";
import {sha256} from "js-sha256";
import actions from "@/api/user"

export default {
  name: "RegisterPage",
  data: () => ({
    show: false,
    password: null,
    passwordConfirm: null,
    valid: false,
    telValid: {},
    formData: {
      accessKey: null,
      name: null,
      surname: null,
      username: null,
      company: null,
      password: null,
      phone: null,
      email: null,
      isPersonal: true,
    },
    alert: {
      type: "",
      message: ""
    },
    rules: rules.userRules
  }),
  methods: {
    async saveFormData() {
      if (this.$refs.form.validate() && (this.telValid.valid !== false && this.telValid.formatted === this.formData.phone)) {
        this.formData.password = sha256(this.password);
        this.formData.accessKey = this.createAccessKey();
        let result;
        try {
          result = await actions.register(this.formData);
        } catch (err) {
          result = err;
        }
        if (result.data) {
          this.alert.type = "success";
          this.alert.message = this.$t('registration.success');
          this.$refs.form.reset();
        } else if (result.response) {
          const errorMessage = this.getMessageFromResponse(result.response.data);
          this.alert.type = "error";
          this.alert.message = errorMessage;
        } else {
          this.alert.type = "error";
          this.alert.message = this.$t('registration.something_went_wrong');
        }
      }
    },
    getMessageFromResponse(response) {
      if (response.includes("users_user_email")) {
        return this.$t('registration.email_exist');
      } else if (response.includes("users_user_username")) {
        return this.$t('registration.username_exist');
      } else {
        return this.$t('registration.something_went_wrong');
      }
    },
    telValidate(telnumber) {
      this.telValid = telnumber
    },
    createAccessKey() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
      );
    }
  }
}
</script>

<style scoped>
.switch-back {
  left: 0;
  transition: all 0.2s linear;
}

.switch-back-right {
  left: calc(50% - 12px);
}

.teldiv {
  margin-bottom: 20px;
  color: black !important;
}

.tel-error:after {
  margin-left: 16px;
  content: attr(data-content);
  display: contents !important;
  font-size: 12px;
  color: rgb(var(--v-theme-error));
}

</style>