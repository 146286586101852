<template>
  <component
      style="min-height: calc(100vh - 300px); background-image:url(https://sayberdata.com/mail-back.jpg);background-size: cover;"
      :is="currentView"/>
</template>

<script>

import routes from './routes.js';
import NotFound from '../pages/NotFound.vue'
import HomePage from '../pages/HomePage.vue'

export default {
  data() {
    return {
      currentPath: window.location,
      currentView: HomePage,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      USER_ACTIVITY_THROTTLER_TIME: 1000,
      INACTIVE_USER_TIME_THRESHOLD: 15 * 60000
    }
  },
  methods: {
    setCurrentView() {
      this.currentView = routes[this.currentPath || '/'] || NotFound
    },
    logout() {
      localStorage.clear();
      window.location.replace(window.location.href);
    },
    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.logout();
      }, this.INACTIVE_USER_TIME_THRESHOLD);
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, this.USER_ACTIVITY_THROTTLER_TIME);
      }
    }
  },
  beforeMount() {
    if (localStorage.getItem("user")) {
      this.activateActivityTracker();
    }
  },
  mounted() {
    const path = window.location.href;
    const basePath = window.location.origin;
    var route = path.slice(basePath.length)
    if (route.includes("#")) {
      route = route.split('#')[0]
    }
    this.currentPath = route;
    this.setCurrentView();
  },
  beforeUnmount() {
    window.removeEventListener("mousemove", this.userActivityThrottler);
    window.removeEventListener("scroll", this.userActivityThrottler);
    window.removeEventListener("keydown", this.userActivityThrottler);
    window.removeEventListener("resize", this.userActivityThrottler);

    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  }
}
</script>