<template>
  <span v-if="!code" :class="verified ? 'text-green' : 'text-purple'" :style="'cursor: ' + (verified ? 'default' : 'pointer')" @click="!verified ? sendCode() : null">{{ verified ? verifiedText : text }}</span>
  <v-row v-else class="m-0">
    <v-row class="justify-content-end align-items-end">
      <div id="inputs" class="mr-1">
        <span>{{ timeText }}: {{ myTime }} {{ second }}</span><br/>
        <div class="d-flex justify-content-between">
          <div v-for="index in 6" :key="index" style="width: 30px" class="m-1">
            <input
                type="tel"
                maxlength="1"
                :class="'border border-bottom border-purple text-center' + (success == null ? '' : (success ? ' success':' error'))"
                style="width: 30px"
                :ref="'OTP-' + index"
                @input="handleInput(index)"
                @keyup.delete="handleBackspace(index)"
                @keyup.enter="checkCode"
            />
          </div>
        </div>
      </div>
      <v-btn
          type="submit"
          class="bg-purple pl-2 pr-2"
          @click="checkCode"
          :disabled="!value"
      >
        {{ buttonText }}
      </v-btn>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "OtpComponent",
  props: {
    text: String,
    timeText: String,
    buttonText: String,
    second: String,
    time: Number,
    onCodeSend: Function,
    onVerified: Function,
    verified: Boolean,
    verifiedText: String
  },
  data() {
    return {
      success: null,
      code: null,
      myTime: null,
      value: null
    }
  },
  methods: {
    sendCode() {
      this.code = this.generateCode();
      if(this.onCodeSend) {
        this.onCodeSend(this.code);
      }
      this.myTime = this.time;
      let that = this;
      setTimeout(() => {
        that.code = null;
        that.myTime = null;
      }, this.myTime*1000)
    },
    generateCode() {
      var digits = '0123456789';
      let OTP = '';
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      return OTP;
    },
    handleInput(e) {
      if (this.$refs["OTP-" + e][0].value.length > 0) {
        if (e < 6) {
          this.value = null;
          e++;
          this.$refs["OTP-" + e][0].focus();
          this.$refs["OTP-" + e][0].select();
        } else {
          this.value = Array.from([1, 2, 3, 4, 5, 6], (i) => {
            return this.$refs["OTP-" + i][0].value || "";
          }).join("");
        }
      }
    },
    handleBackspace(e) {
      if (e > 1) {
        this.value = null;
        e--;
        this.$refs["OTP-" + e][0].focus();
        this.$refs["OTP-" + e][0].select();
      }
    },
    checkCode(){
      if(this.code){
        if(this.code == this.value){
          if(this.onVerified) {
            this.onVerified();
          }
          this.success = true;
          let that = this;
          setTimeout(()=>{
            that.code = null;
            that.myTime = null;
            that.success = null;
          },2000)
        }else{
          this.success = false;
        }
      }
    }
  },
  watch: {
    myTime: {
      handler(value) {
        if (value && value > 0) {
          setTimeout(() => {
            this.myTime--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  }
}
</script>

<style scoped>
.success{
  border-color: green !important;
  border-width: 2px !important;
}
.error{
  border-color: red !important;
  border-width: 2px !important;
}
</style>