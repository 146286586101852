import {createApp} from 'vue'
import App from './App.vue'
import {loadFonts} from './plugins/webfontloader'
import i18n from './i18n'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

loadFonts()
const vuetify = createVuetify({
    components,
    directives,
})
createApp(App)
    .use(vuetify)
    .use(i18n)
    .use(VueTelInput)
    .mount('#app')